@import "../../../assets/scss/variable.scss";

.discovermain {
  background-image: url(https://res.cloudinary.com/zudu/image/upload/v1657710591/Birlinn/App/Project-images/MainBg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // height: 100vh !important;
  display: flex;
  overflow: hidden;
  position: relative;

  .discoverleft {
    width: 15%;
    max-width: 180px;
    height: auto;
    z-index: 1;
    background-color: $BODY_BG;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);

    .navbar {
      padding: 0;
      display: block;
      max-width: 180px;

      .navbar-logo {
        display: block;
        margin: 0;
        padding: 33.34% 6.67% 0;
        margin-bottom: 23px;

        img {
          max-width: 74%;
          height: auto;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .navbar-collapse {
        display: block !important;
        justify-content: center;
        align-items: center;
        padding: 0 17.78%;

        .nav-link {
          position: relative;
          display: block;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          margin: 8px 0;
          padding: 12.08% 0;
          text-align: center;
          font-family: $HELVETICA;
          color: $TEXT_WHITE;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_12;
          background: $OFFSET_BG;
          box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);

          &.active {
            background: $BG_ORANGE;
            box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
          }

          i {
            font-size: $FONT_22;
          }

          span {
            display: block;
          }

          &:hover {
            background: $BG_ORANGE;
            box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
          }
        }
      }
    }
  }

  .discoverright {
    padding: 0 2.5%;
    width: 100%;
    padding-top: 24px;
    overflow: hidden;

    .mapPosition {
      z-index: 999;
      pointer-events: none;
    }

    .searchbar_area.style_list {
      .searchBoxDiv {
        .searchInput {
          border-radius: 10px 10px 0 0;
        }
      }
    }

    .searchbar_area {
      position: relative;
      z-index: 9;

      .searchBoxDiv {
        max-width: 600px;

        &:after {
          content: '';
        }

        .search-icon {
          left: 0;
        }

        .searchInput {
          padding: 6px 45px 6px 40px;
        }
      }

      ul {
        margin: 0;
        padding: 5px 0;
        background-color: $BG_WHITE;
        height: auto;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        position: absolute;
        left: 0;
        top: 40px;
        width: 100%;
        max-width: 600px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        display: none;

        &::-webkit-scrollbar {
          width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #66000000;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #c0c0c0;
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: $SCROLLBAR_BG;
        }


        li {
          list-style: none;
          padding: 0 20px;
          font-size: 15px;
          cursor: pointer;
        }
      }

      ul.list_show {
        display: block !important;
      }
    }

    .map_area {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      padding-bottom: 15px;

      .map_img {
        display: flex;
        justify-content: center;
        transition: transform .4s ease-in-out;
        position: relative;
        transform: scale(1);
        width: auto;
        height: auto;

        svg.map_style {
          padding-bottom: 9%;
          width: 100%;
          min-width: 400px;
          max-width: 560px;
        }

        .bgcolor-0 {

          .region_color0 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-1 {

          .region_color1 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-2 {

          .region_color2 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-3 {

          .region_color3 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-4 {

          .region_color4 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-5 {

          .region_color5 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-6 {

          .region_color6 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-7 {

          .region_color7 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-8 {

          .region_color8 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-9 {

          .region_color9 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-10 {

          .region_color10 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-11 {

          .region_color11 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-12 {

          .region_color12 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-13 {

          .region_color13 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-14 {

          .region_color14 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-15 {

          .region_color15 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-16 {

          .region_color16 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-17 {

          .region_color17 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-18 {

          .region_color18 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-19 {

          .region_color19 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-20 {

          .region_color20 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-21 {

          .region_color21 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-22 {

          .region_color22 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-23 {

          .region_color23 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        .bgcolor-24 {

          .region_color24 {
            fill: $BG_DARK_GREEN_MAP !important;
          }
        }

        a.\30 {
          width: calc(100% - 95.8%);
          position: absolute;
          left: 42%;
          top: 46%;
          cursor: pointer;
        }

        a.\31 {
          width: calc(100% - 95.8%);
          position: absolute;
          left: 16%;
          top: 37%;
          cursor: pointer;
        }

        a.\32 {
          width: calc(100% - 95.8%);
          position: absolute;
          right: 11%;
          top: 6%;
          cursor: pointer;
        }

        a.\33 {
          width: calc(100% - 95.8%);
          position: absolute;
          right: 28%;
          top: 23%;
          cursor: pointer;
        }

        a.\34 {
          width: calc(100% - 95.8%);
          position: absolute;
          top: 51%;
          right: 14%;
          cursor: pointer;
        }

        a.\35 {
          width: calc(100% - 95.8%);
          position: absolute;
          top: 47%;
          right: 28%;
          cursor: pointer;
        }

        a.\36 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 35%;
          right: 36%;
          cursor: pointer;
        }

        a.\37 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 36%;
          right: 22%;
          cursor: pointer;
        }

        a.\38 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 30%;
          right: 25%;
          cursor: pointer;
        }

        a.\39 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 25%;
          right: 30%;
          cursor: pointer;
        }

        a.\31 0 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 29%;
          left: 37%;
          cursor: pointer;
        }

        a.\31 1 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 31%;
          left: 48%;
          cursor: pointer;
        }

        a.\31 2 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 26%;
          right: 50%;
          cursor: pointer;
        }

        a.\31 3 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 28.7%;
          right: 37%;
          cursor: pointer;
        }

        a.\31 4 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 19.5%;
          left: 56.5%;
          cursor: pointer;
        }

        a.\31 5 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 26%;
          right: 38%;
          cursor: pointer;
        }

        a.\31 6 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 24%;
          right: 34%;
          cursor: pointer;
        }

        a.\31 7 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 23%;
          right: 27%;
          cursor: pointer;
        }

        a.\31 8 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 25%;
          right: 20%;
          cursor: pointer;
        }

        a.\31 9 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 23.3%;
          left: 46%;
          cursor: pointer;
        }

        a.\32 0 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 23.5%;
          left: 50%;
          cursor: pointer;
        }

        a.\32 1 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 17%;
          cursor: pointer;
        }

        a.\32 2 {
          width: calc(100% - 95.8%);
          position: absolute;
          bottom: 11%;
          right: 40%;
          cursor: pointer;
        }

        a.\32 3 {
          width: calc(100% - 95.8%);
          position: absolute;
          ;
          right: 19%;
          bottom: 18%;
          cursor: pointer;
        }

        a.\32 4 {
          width: 4.2%;
          position: absolute;
          cursor: pointer;
          bottom: 25%;
          left: 42%;
        }

        a img {
          width: 100%;
        }

      }

      .map_img.pin-0 {
        transform: scale(2) translate(-21%, -4%);
      }

      .map_img.pin-1 {
        transform: scale(2) translate(0%, 5%);
      }

      .map_img.pin-2 {
        transform: scale(2) translate(-40%, 30%);
      }

      .map_img.pin-3 {
        transform: scale(2) translate(-31%, 25%);
      }

      .map_img.pin-4 {
        transform: scale(2) translate(-38%, -4%);
      }

      .map_img.pin-5 {
        transform: scale(2) translate(-21%, -4%);
      }

      .map_img.pin-6 {
        transform: scale(2) translate(-21%, -14%);
      }

      .map_img.pin-7 {
        transform: scale(2) translate(-30%, -14%);
      }

      .map_img.pin-8 {
        transform: scale(2) translate(-28%, -21%);
      }

      .map_img.pin-9 {
        transform: scale(2) translate(-21%, -20%);
      }

      .map_img.pin-10 {
        transform: scale(2) translate(-21%, -18%);
      }

      .map_img.pin-11 {
        transform: scale(2) translate(-21%, -16%);
      }

      .map_img.pin-12 {
        transform: scale(2) translate(-21%, -18%);
      }

      .map_img.pin-13 {
        transform: scale(2) translate(-21%, -18%);
      }

      .map_img.pin-14 {
        transform: scale(2) translate(-21%, -18%);
      }

      .map_img.pin-15 {
        transform: scale(2) translate(-21%, -17%);
      }

      .map_img.pin-16 {
        transform: scale(2) translate(-21%, -18%);
      }

      .map_img.pin-17 {
        transform: scale(2) translate(-21%, -23%);
      }

      .map_img.pin-18 {
        transform: scale(2) translate(-29%, -25%);
      }

      .map_img.pin-19 {
        transform: scale(2) translate(-21%, -20%);
      }

      .map_img.pin-20 {
        transform: scale(2) translate(-21%, -20%);
      }

      .map_img.pin-21 {
        transform: scale(2) translate(-21%, -27%);
      }

      .map_img.pin-22 {
        transform: scale(2) translate(-21%, -25%);
      }

      .map_img.pin-23 {
        transform: scale(2) translate(-28%, -30%);
      }

      .map_img.pin-24 {
        transform: scale(2) translate(-30%, -25%);
      }

    }

    button.info_text {
      position: fixed;
      bottom: 30px;
      right: 30px;
      border: none;
      background: $BG_YELLLOW;
      color: $TEXT_WHITE;
      font-size: $FONT_30;
      border-radius: 50%;
      height: 40px !important;
      padding: 0 !important;
      display: block !important;
      max-width: 40px;
      margin-right: 0;
      width: 100%;
    }
  }

  .regions_details {
    max-width: 415px;
    width: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
    transition: transform .4s ease-in-out;
    transform: translate3d(100%, 0px, 0px);
    z-index: 11111;

  }

  .regions_details.open {
    transform: translate3d(0%, 0px, 0px);
    z-index: 1111;
  }

  .regions_details.open.style_map {
    position: relative !important;
  }

  .discoverright.dnone {
    display: none;
  }

}

.gmap {
  display: none;
}

.googlemap_area {
  width: 100%;
  display: block !important;

  .map_top_bar {
    position: absolute;
    top: 0;
    margin: 24px 0 0 24px;
    display: flex;
    width: calc(100% - 490px);

    .btn_searchbar {
      display: flex;
      width: 100%;

      .back_region_btn {
        background: $BG_ORANGE;
        border-radius: 8px;
        align-items: center;
        padding: 0px 8px;
        cursor: pointer;
        display: flex;
        min-width: fit-content;

        span.back_arrow {
          font-size: $FONT_24;
          align-items: center;
          display: flex;
          color: $TEXT_WHITE;
        }

        .text_area {
          padding: 4px 0 4px 4px;

          p:nth-child(1) {
            font-family: 'Lobster';
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_18;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: $BG_WHITE;
            margin: 0;
          }

          p:nth-child(2) {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 12px;
            color: $BG_WHITE;
            margin: 0;
          }
        }
      }

      .search_bar {
        padding: 0 12px;
        width: 100%;
        max-height: 40px;

        .searchBoxDiv {
          position: relative;
          width: 100%;

          input.searchInput {
            padding: 6px 25px 6px 45px;
            height: 43px;
          }

          .search-icon {
            left: 0;
          }
        }

        .searchBoxDiv:after {
          font-size: 0;
          display: none;
        }
      }
    }

    .filter {
      display: flex;

      .map_filter_btn {
        display: flex;
        gap: 10px;

        .map_btn {
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid $BG_WHITE;
          box-shadow: 2px 2px 0px rgba(55, 65, 81, 0.25);
          border-radius: 8px;
          align-items: center;
          padding: 4px 10px;
          display: flex;
          width: 94.14px;
          height: 40px;
          justify-content: center;
          color: rgba(1, 14, 25, 0.5);
          gap: 10px;
          font-size: $FONT_20;
          cursor: pointer;

          span {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
          }
        }

        .map_btn.active {
          background: $BG_WHITE !important;
          box-shadow: 4px 4px 0px rgb(255 255 255 / 25%) !important;
        }

        .map_btn.books.active {
          background: $BG_DARK_SKY_BLUE !important;
          box-shadow: 4px 4px 0px rgb(17 116 187 / 25%) !important;
          color: $TEXT_WHITE;
        }

        .map_btn.shops.active {
          background: $BG_RED_LIGHT !important;
          box-shadow: 4px 4px 0px rgba(234, 81, 104, 0.25) !important;
          color: $TEXT_WHITE;
        }

        .map_btn.events.active {
          background: $BG_YELLLOW_LIGHT !important;
          box-shadow: 4px 4px 0px rgba(253, 200, 78, 0.25) !important;
          color: $TEXT_WHITE;
        }
      }
    }
  }

  .map_top_back {
    position: absolute;
    top: 0;
    margin: 24px 0 0 24px;

    .back_btn {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      background: $BG_WHITE;
      border: 1px solid $BG_WHITE;
      border-radius: 8px;

      span {
        font-size: $FONT_24;
        display: flex;
      }

      p {
        margin: 0;
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_16;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        color: #374151;
        padding-left: 10px;
      }
    }
  }
}

.main_contain_data {
  padding: 2.892% 4.82% 0;
  overflow-y: scroll;
  padding-bottom: 50px;

  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #66000000;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $SCROLLBAR_BG;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
  }

  .no_data {
    font-family: "Lobster";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 42px;
    color: $TEXT_WHITE;
    width: 100%;
    text-align: center;
    padding-top: 50px;
  }

  .books_cards {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 10px;
    width: 399px;
    max-width: 100%;
    margin-bottom: 10px;
    cursor: pointer;

    .book_card_area {
      display: flex;
      position: relative;

      .left_side {
        img {
          width: 90px;
          height: 100%;
          min-height: 120px;
        }
      }

      .right_side {
        display: flex;

        .contain {
          margin-left: 10px;

          p.date {
            margin: 0;
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 14px;
            color: $TEXT_BLUE;
          }

          h3.book_name {
            margin: 10px 0 0 0;
            font-family: $HELVETICA;
            font-weight: 700;
            font-size: $FONT_18;
            line-height: 18px;
            letter-spacing: 0.15px;
            color: $TEXT_BLUE;
          }

          h4.book_author {
            margin: 10px 0 0 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 14px;
            color: $TEXT_BLUE;
          }

          h4.novel_name {
            margin: 10px 0 0 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: $TEXT_BLUE;
          }

          h3.region_name {
            margin: 10px 0 0 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 700;
            font-size: $FONT_12;
            line-height: 14px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            font-size: $TEXT_DARK_BLUE_DARK;
          }
        }

        .bookmark_area {
          position: absolute;
          right: 0;

          span.icon-fav {
            font-size: $FONT_24;
            color: $TEXT_WHITE;
            cursor: pointer;
          }

          span.icon-heart-fill {
            font-size: $FONT_24;
            color: $TEXT_WHITE;
            cursor: pointer;
          }
        }
      }
    }
  }

  .events_card {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    max-width: 100%;
    width: 399px;
    margin-bottom: 10px;
    cursor: pointer;

    .event_card_area {
      padding: 10px;

      .top_side {

        img {
          width: 100%;
        }
      }

      .down_side {

        .name_bookmark {
          display: flex;
          position: relative;
          margin-top: 10px;
          padding-bottom: 14px;
          justify-content: space-between;

          .event_name {

            h4 {
              font-family: $HELVETICA;
              font-weight: 700;
              font-size: $FONT_18;
              line-height: 18px;
              letter-spacing: 0.15px;
              color: $TEXT_BLUE;
              margin: 0;
            }
          }

          .bookmark_events {

            span.icon-fav {
              font-size: $FONT_24;
              color: $TEXT_WHITE;
              cursor: pointer;
            }

            span.icon-heart-fill {
              font-size: $FONT_24;
              color: $TEXT_WHITE;
              cursor: pointer;
            }
          }
        }

        .region_month {
          display: flex;

          .region_name {
            width: 70%;

            p {
              font-family: $HELVETICA;
              font-style: normal;
              font-weight: 700;
              font-size: $FONT_12;
              line-height: 14px;
              letter-spacing: 0.08em;
              text-transform: uppercase;
              font-size: $TEXT_DARK_BLUE_DARK;
              margin: 0;
            }
          }

          .month_name {
            width: 30%;
            display: flex;
            justify-content: end;

            p {
              font-family: $HELVETICA;
              font-style: normal;
              font-weight: 400;
              font-size: $FONT_12;
              line-height: 14px;
              display: flex;
              align-items: center;
              color: $TEXT_BLUE;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .shop_cards {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 10px;
    gap: 10px;
    max-width: 100%;
    width: 399px;
    margin-bottom: 10px;
    cursor: pointer;

    .contain_area {
      display: flex;
      position: relative;
      justify-content: space-between;

      .main_contain {
        display: flex;
        padding: 0;

        .shop_img {

          img {
            width: 100%;
            max-width: 120px;
            min-height: 140px;
          }
        }

        .shop_text {
          margin-left: 10px;

          .time {

            p {
              font-family: $HELVETICA;
              font-style: normal;
              font-weight: 400;
              font-size: $FONT_12;
              line-height: 14px;
              color: $TEXT_BLUE;
              margin: 0;
            }
          }

          .shop_name {

            p {
              font-family: $HELVETICA;
              font-style: normal;
              font-weight: 700;
              font-size: $FONT_18;
              line-height: 18px;
              letter-spacing: 0.15px;
              color: $TEXT_BLUE;
              margin: 0;
              padding-top: 10px;
            }
          }

          .region_name {

            p {
              font-family: $HELVETICA;
              font-style: normal;
              font-weight: 700;
              font-size: $FONT_12;
              line-height: 14px;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              text-transform: uppercase;
              margin: 0;
              padding-top: 10px;
            }
          }
        }
      }

      .shop_bookmark {

        span.icon-fav {
          font-size: $FONT_24;
          color: $TEXT_WHITE;
          cursor: pointer;
        }

        span.icon-heart-fill {
          font-size: $FONT_24;
          color: $TEXT_WHITE;
          cursor: pointer;
        }
      }
    }
  }

  .books_details {

    .details_section {

      .book_img {

        img {
          width: 100%;
          // max-width: 100px;
          max-width: 100%;
          // min-height: 140px;
          height: auto;
        }
      }

      .text_details_n_bookmark {
        display: flex;
        padding-top: 20px;

        .text_details {
          width: 100%;

          p.date {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 14px;
            color: $BG_WHITE;
          }

          p.region_name {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 700;
            font-size: $FONT_12;
            line-height: 14px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: $BG_WHITE;
            padding-top: 8px;
          }

          h3.book_name {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 700;
            font-size: $FONT_18;
            line-height: 18px;
            letter-spacing: 0.15px;
            color: $BG_WHITE;
            padding-top: 8px;
          }

          h4.novel_name {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: $BG_WHITE;
            padding-top: 8px;
          }

          p.author_name {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 14px;
            color: $BG_WHITE;
            margin: 0;
            padding-top: 8px;
          }

          p.price {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: $BG_WHITE;
            padding-top: 24px;
          }
        }

        .bookmark_icon {
          color: $TEXT_WHITE;
          font-size: $FONT_24;
          cursor: pointer;
        }
      }

      .book_tag {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
        padding-top: 24px;

        p {
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 6px 20px;
          background: $BG_WHITE_LIGHT;
          border: 1px solid $BG_WHITE;
          border-radius: 8px;

          span {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: $TEXT_BLUE_DARK;
          }
        }
      }

      .books_btn {
        padding-top: 24px;
        display: flex;

        a.buy_btn {
          background: $BG_ORANGE;
          border: 1px solid $BG_WHITE;
          border-radius: 8px;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_14;
          line-height: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.06em;
          color: $BG_WHITE;
          padding: 8px 16px;
          text-decoration: none;
        }

        a.right_turn_btn {
          background: $BG_ORANGE;
          border: 1px solid $BG_WHITE;
          border-radius: 8px;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_24;
          line-height: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.06em;
          color: $BG_WHITE;
          padding: 4px 3px;
          width: 34px;
          margin-left: 16px;
          text-decoration: none;
        }
      }
    }

    .about_books_section {
      padding-top: 20px;

      .about_book {

        p.title {
          margin-bottom: 0;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_18;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: 0.15px;
          color: $BG_WHITE;
        }

        p.text {
          margin: 0;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 400;
          font-size: $FONT_14;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: $BG_WHITE;
          padding-top: 8px;

          iframe {
            max-width: 100%;
          }
        }
      }
    }

    .about_book_img {
      padding-top: 20px;

      img {
        width: 100%;
      }
    }

    .about_author {
      padding-top: 20px;

      p.title {
        margin-bottom: 0;
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 700;
        font-size: $FONT_18;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: 0.15px;
        color: $BG_WHITE;
      }

      p.text {
        margin: 0;
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_14;
        line-height: 16px;
        letter-spacing: 0.25px;
        color: $BG_WHITE;
        padding-top: 8px;
      }
    }
  }

  .shop_details {

    .shop_section {

      .shop_img {

        img {
          width: 100%;
          // max-width: 100px;
          max-width: 100%;
          // min-height: 140px;
          height: auto;
        }
      }

      .text_details_n_bookmark {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;

        .text_details {
          width: 100%;

          p.region_name {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 700;
            font-size: $FONT_12;
            line-height: 14px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: $BG_WHITE;
            padding-top: 8px;
          }

          h3.shop_name {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 700;
            font-size: $FONT_18;
            line-height: 18px;
            letter-spacing: 0.15px;
            color: $BG_WHITE;
            padding-top: 8px;
          }

          h4.shop_add {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: $BG_WHITE;
            padding-top: 8px;
          }

          .drop_down_time {
            display: flex;
            justify-content: space-between;

            .defult_time {

              p {
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 400;
                font-size: $FONT_12;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: $BG_WHITE;
                padding-top: 8px;
              }
            }

            ul {
              margin: 0;
              padding: 0;

              li {
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 400;
                font-size: $FONT_12;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: $BG_WHITE;
                padding-top: 8px;
              }
            }

            .drop_down_icon {
              color: $TEXT_WHITE;
              font-size: $FONT_16;
              cursor: pointer;
            }
          }
        }

        .bookmark_icon {
          color: $TEXT_WHITE;
          font-size: $FONT_24;
          cursor: pointer;
        }
      }

      .shop_tag {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
        padding-top: 24px;

        p {
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 6px 20px;
          background: $BG_WHITE_LIGHT;
          border: 1px solid $BG_WHITE;
          border-radius: 8px;

          span {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: $TEXT_BLUE_DARK;
          }
        }
      }

      .shop_btn {
        padding-top: 24px;
        display: flex;

        a.btn_shop {
          background: $BG_ORANGE;
          border: 1px solid $BG_WHITE;
          border-radius: 8px;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_24;
          line-height: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.06em;
          color: $BG_WHITE;
          padding: 4px 4px;
          width: 34px;
          margin-right: 16px;
          text-decoration: none;
        }
      }

      ul {
        margin: 20px 0 0 0;
        padding: 0;

        li {
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_14;
          color: $BG_WHITE;
          list-style: none;
        }
      }
    }

    .about_shop_section {
      padding-top: 20px;

      .about_shop {

        p.title {
          margin-bottom: 0;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_18;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: 0.15px;
          color: $BG_WHITE;
        }

        p.text {
          margin: 0;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 400;
          font-size: $FONT_14;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: $BG_WHITE;
          padding-top: 8px;
        }
      }
    }

    .about_shop_img {
      padding-top: 20px;

      img {
        width: 100%;
      }
    }
  }

  .event_details {

    .event_section {

      .event_img {
        // height: 225px;
        height: auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .text_details_n_bookmark {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;

        .text_details {
          width: 100%;

          p.region_name {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 700;
            font-size: $FONT_12;
            line-height: 14px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: $BG_WHITE;
            padding-top: 8px;
          }

          h3.event_name {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 700;
            font-size: $FONT_18;
            line-height: 18px;
            letter-spacing: 0.15px;
            color: $BG_WHITE;
            padding-top: 8px;
          }

          h4.event_add {
            margin: 0;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: $BG_WHITE;
            padding-top: 8px;
          }

          .drop_down_time {
            display: flex;
            justify-content: space-between;

            .defult_time {

              p {
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 400;
                font-size: $FONT_12;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: $BG_WHITE;
                padding-top: 8px;
              }
            }

            ul {
              margin: 0;
              padding: 0;

              li {
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 400;
                font-size: $FONT_12;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: $BG_WHITE;
                padding-top: 8px;
              }
            }

            .drop_down_icon {
              color: $TEXT_WHITE;
              font-size: $FONT_16;
              cursor: pointer;
            }
          }
        }

        .bookmark_icon {
          color: $TEXT_WHITE;
          font-size: $FONT_24;
          cursor: pointer;
        }
      }

      .event_tag {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
        padding-top: 24px;

        p {
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 6px 20px;
          background: $BG_WHITE_LIGHT;
          border: 1px solid $BG_WHITE;
          border-radius: 8px;

          span {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: $TEXT_BLUE_DARK;
          }
        }
      }

      .event_btn {
        padding-top: 24px;
        display: flex;

        a.text_btn {
          flex-direction: row;
          justify-content: center;
          padding: 8px 16px;
          background: $BG_ORANGE;
          border-radius: 8px;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_14;
          line-height: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.06em;
          color: $BG_WHITE;
          border: none;
          margin-right: 16px;
          text-decoration: none;
        }

        a.btn_event {
          background: $BG_ORANGE;
          border: 1px solid $BG_WHITE;
          border-radius: 8px;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_24;
          line-height: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.06em;
          color: $BG_WHITE;
          padding: 4px 3px;
          width: 34px;
          margin-right: 16px;
          text-decoration: none;
        }
      }
    }

    .about_event_section {
      padding-top: 20px;

      .about_event {

        p.title {
          margin-bottom: 0;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_18;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: 0.15px;
          color: $BG_WHITE;
        }

        p.text {
          margin: 0;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 400;
          font-size: $FONT_14;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: $BG_WHITE;
          padding-top: 8px;
        }
      }
    }

    .about_event_img {
      padding-top: 20px;

      img {
        width: 100%;
      }
    }
  }
}

.videopop {
  z-index: 111111 !important;
  overflow-y: hidden !important;

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    max-width: 100%;

    .modal-content {
      height: 100%;

      .modal-body {
        padding: 0;
        z-index: 111111;

        iframe {
          border: 0;
          height: 100%;
        }
      }
    }
  }
}

.gmap span.gmap_pins {
  cursor: pointer;
}

.fade.confirmModal.remove_bookmark_popup.modal.show {
  z-index: 999999999;
}

.fade.modal.info_modal.modal.show {
  z-index: 111111;
}

.fade.modal.info_modal.modal .modal-dialog.modal-dialog-centered .modal-content {
  background: none;
}

.fade.modal.info_modal.modal .modal-dialog.modal-dialog-centered {
  width: 100%;
  max-width: 770px;
  overflow-y: auto;
  transform: none;
  margin: 0 auto !important;

  .info_text_area {
    padding: 15px;
    border-radius: 15px;
    background: $BG_WHITE;

    .close_icon {
      text-align: end;

      span.icon-x {
        font-size: $FONT_30;
        cursor: pointer;
        background: $BG_GRAY_DARK;
        color: $TEXT_WHITE;
        border-radius: 50%;
      }
    }

    ul {
      li {
        margin: 0 0 10px 0;
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_16;
        line-height: 24px;
        text-align: justify;
        letter-spacing: 0.5px;
      }
    }

    p {
      margin: 0 0 20px 0;
      font-family: $HELVETICA;
      font-style: normal;
      font-weight: 400;
      font-size: $FONT_16;
      line-height: 24px;
      text-align: justify;
      letter-spacing: 0.5px;
    }
  }


}

.react-transform-wrapper.transform-component-module_wrapper__1_Fgj {
  width: 100%;

  .react-transform-component.transform-component-module_content__2jYgh {
    width: 100%;
  }
}

.map_zoom_out-0,
.map_zoom_out-1,
.map_zoom_out-2,
.map_zoom_out-3,
.map_zoom_out-4,
.map_zoom_out-5,
.map_zoom_out-6,
.map_zoom_out-7,
.map_zoom_out-8,
.map_zoom_out-9,
.map_zoom_out-10,
.map_zoom_out-11,
.map_zoom_out-12,
.map_zoom_out-13,
.map_zoom_out-14,
.map_zoom_out-15,
.map_zoom_out-16,
.map_zoom_out-17,
.map_zoom_out-18,
.map_zoom_out-19,
.map_zoom_out-20,
.map_zoom_out-21,
.map_zoom_out-22,
.map_zoom_out-23,
.map_zoom_out-24,
.map_zoom_out-25 {
  .react-transform-wrapper.transform-component-module_wrapper__1_Fgj .react-transform-component.transform-component-module_content__2jYgh {
    transform: none !important;
  }
}


@media only screen and (max-width: 1600px) {

  .googlemap_area {

    .map_top_bar {
      display: block;
      width: calc(100% - 450px);

      .btn_searchbar {

        .search_bar {

          .searchBoxDiv {
            max-width: 100%;
          }
        }
      }
    }

    .filter {
      margin-top: 10px;

      .map_filter_btn {
        width: 100%;
        padding-right: 12px;

        .map_btn {
          width: 48% !important;
        }
      }
    }
  }

  .gmap button.gm-control-active.gm-fullscreen-control {
    display: none;
  }

  .gmap .gmnoprint {
    display: none;
  }
}

@media only screen and (max-width: 1150px) {
  .googlemap_area {
    .map_top_bar {
      .btn_searchbar {
        display: block;
        width: 98%;

        .back_region_btn {
          margin-bottom: 10px;
        }

        .search_bar {
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 985px) {
  .googlemap_area {
    .map_top_bar {
      .filter {
        display: block;

        .map_filter_btn {
          display: flex;
          flex-wrap: wrap;
          width: 98%;
          padding: 0;

          .map_btn {
            margin-bottom: 0px;
            background: rgba(255, 255, 255, 1);

            &.active {
              background: #f7a637 !important;
              box-shadow: 4px 4px 0px rgb(255 255 255 / 25%) !important;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 910px) {
  .discovermain .regions_details {
    max-width: 340px;
  }

  .googlemap_area .map_top_bar {
    width: calc(100% - 380px);
  }
}

@media only screen and (max-width: 870px) {
  .fade.modal.info_modal.modal .modal-dialog.modal-dialog-centered {
    max-width: 650px;
  }
}

@media only screen and (max-width: 810px) {

  .discovermain .discoverright .map_area,
  .discovermain .discoverright .map_area .map_img {
    width: calc(100% - 14%);
  }

  .discovermain .discoverright .map_area .map_img svg.map_style {
    max-width: 500px;
  }

  .discovermain .regions_details {
    max-width: 340px;
  }

  .googlemap_area .map_top_bar {
    width: calc(100% - 380px);
  }
}

@media only screen and (max-width: 765px) {

  .discovermain .discoverright .map_area,
  .discovermain .discoverright .map_area .map_img {
    width: calc(100% - 13%);
  }
}

@media only screen and (max-width: 748px) {

  .discovermain .discoverright .map_area,
  .discovermain .discoverright .map_area .map_img {
    width: calc(100% - 12%);
  }
}

@media only screen and (max-width: 730px) {

  .discovermain .discoverright .map_area,
  .discovermain .discoverright .map_area .map_img {
    width: calc(100% - 11%);
  }
}

@media only screen and (max-width: 715px) {

  .discovermain .discoverright .map_area,
  .discovermain .discoverright .map_area .map_img {
    width: calc(100% - 10%);
  }
}

@media only screen and (max-width: 710px) {

  .discovermain .discoverright .map_area,
  .discovermain .discoverright .map_area .map_img {
    width: calc(100% - 9%);
  }
}

@media only screen and (max-width: 690px) {

  .discovermain .discoverright .map_area,
  .discovermain .discoverright .map_area .map_img {
    width: calc(100% - 8%);
  }
}

@media only screen and (max-width: 678px) {

  .discovermain .discoverright .map_area,
  .discovermain .discoverright .map_area .map_img {
    width: calc(100% - 7%);
  }
}

@media only screen and (max-width: 665px) {

  .discovermain .discoverright .map_area,
  .discovermain .discoverright .map_area .map_img {
    width: calc(100% - 6%);
  }
}

@media only screen and (max-height: 1370px) {
  .discovermain .discoverright .map_area {
    max-height: 855px;
  }
}

@media only screen and (max-height: 1035px) {
  .discovermain .discoverright .map_area {
    max-height: 960px;
  }
}

@media only screen and (max-height: 960px) {
  .discovermain .discoverright .map_area {
    max-height: 800px;
  }
}

@media only screen and (max-height: 872px) {
  .discovermain .discoverright .map_area {
    max-height: 800px;
  }
}

@media only screen and (max-height: 680px) {
  .discovermain .discoverright .map_area .map_img svg.map_style {
    min-width: 380px;
  }

}

@media only screen and (max-height: 650px) {
  .discovermain .discoverright .map_area .map_img svg.map_style {
    min-width: 340px;
  }

}

@media only screen and (max-height: 605px) {
  .discovermain .discoverright .map_area .map_img svg.map_style {
    min-width: 315px;
  }

}

@media only screen and (max-height: 560px) {
  .discovermain .discoverright .map_area .map_img svg.map_style {
    min-width: 285px;
  }

}

@media only screen and (max-height: 510px) {
  .discovermain .discoverright .map_area .map_img svg.map_style {
    min-width: 260px;
  }

}