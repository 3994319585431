@import "../../assets/scss/variable.scss";

.mainHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 180px;
  height: 100%;
  z-index: 1;
  background-color: $BODY_BG;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  background-image: url("https://res.cloudinary.com/zudu/image/upload/v1654764712/Birlinn/Web/Project-Images/background.svg");

  .leftHeader {
    position: absolute;
    left: 0;
    right: 0;
    top: -0.03%;
    bottom: 0;

    .navbar {
      padding: 0;
      display: block;
      .navbar-logo {
        display: block;
        margin: 0;
        padding: 60px 12px 0;
        margin-bottom: 23px;

        &:focus-visible {
          outline: none !important;
        }

        img {
          max-width: 74%;
          height: auto;
          display: block;
          margin-left: auto;
          margin-right: auto;

          &:focus-visible {
            outline: none !important;
          }
        }
      }
      .navbar-collapse {
        display: block !important;
        justify-content: center;
        align-items: center;
        padding: 0 32px;

        .nav-link {
          position: relative;
          display: block;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          margin: 8px 0;
          padding: 14px 0;
          text-align: center;
          font-family: $HELVETICA;
          color: $TEXT_WHITE;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_12;
          background: $OFFSET_BG;
          box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);

          &.active {
            background: $BG_ORANGE;
            box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
          }

          i {
            font-size: $FONT_22;
          }

          span {
            display: block;
          }

          &:hover {
            background: $BG_ORANGE;
            box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
          }
        }
      }
    }
  }
}
.nav {
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  height: 100%;
  max-height: 48px;
  margin: 20px 40px 0 0;
  background: $BG_NAVBAR;
  box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
  justify-content: flex-start;
  .alignCenter {
    margin: 14px 8px 14px 16px;
    i {
      color: $TEXT_BLUE;
      font-size: $FONT_20;
    }
    a {
      text-decoration: none;
    }
  }
  .profileMain {
    padding: 5px;
    .titleName {
      font-family: "Helvetica";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.15px;
      color: $TEXT_BLUE;
      white-space: nowrap;
      width: 109px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .titleEmail {
      font-family: "Helvetica";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 11px;
      color: $TEXT_BLUE;
    }
  }
  .imgArea {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    font-size: $FONT_20;
    text-transform: uppercase;

    .blankProfile {
      padding-right: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      max-width: 48px;
      max-height: 48px;
      object-fit: contain;
    }
  }
}
.loginOffcanvas {
  width: 550px !important;
  background: $BODY_BG !important;
  .offcanvas-header {
    padding: 36px;
    padding-bottom: 0px !important;
    .alignCenter {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
        cursor: pointer;
      }
      a {
        text-decoration: none;
      }
    }
  }
  .offcanvas-body {
    padding: 0 50px 50px 50px;
    background-color: $BODY_BG;
    position: relative;
    // display: flex;
    // justify-content: center;

    .dropdown-toggle {
      position: absolute;
      right: 33%;
      bottom: 0%;
      background: $BG_ORANGE !important;
      border: none !important;
      border-radius: 0;
      box-shadow: none !important;

      &::after {
        content: "\e918";
        font-family: "birlinn" !important;
        border: none;
        font-size: $FONT_18;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .dropdown-menu {
      border-radius: 0px;
      transform: none !important;
      right: 30px !important;
      left: auto !important;
      font-family: $HELVETICA;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: $TEXT_DISABLE;
      margin-bottom: 0.2px solid $TEXT_DISABLE;
      margin: 0;
      padding: 0;

      .dropdown-item {
        padding: 11px;
        border-bottom: 0.2px solid $TEXT_DISABLE;
        &:active {
          background-color: transparent !important;
          color: $BG_BLACK;
        }
      }
    }
    .uploadedImg {
      margin-left: auto;
      margin-right: auto;
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 1px solid $BG_WHITE;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
      .blankProfile {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_18;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.4px;
        color: $TEXT_WHITE;
      }
    }
    .userName {
      font-family: "Lobster";
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 130%;
      text-align: center;
      letter-spacing: -0.25px;
      color: $TEXT_WHITE;
      padding-top: 10px;
      margin: 0 !important;
    }
    .userEmail {
      font-family: "Helvetica";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: $TEXT_WHITE;
      padding-bottom: 30px;
      margin: 0 !important;
    }
    .custombtnfieldupdate {
      margin-top: 14px !important;
      max-width: 105px;
      min-height: 40px;
      margin-left: 173px;
    }
  }
  .passwordInline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 91%;
    float: left;
    margin-bottom: 30px;
    .changePassword {
      font-family: $HELVETICA;
      font-style: normal;
      font-weight: 700;
      font-size: $FONT_18;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $TEXT_WHITE;
      padding-left: 50px;
      padding-bottom: 30px;
      cursor: pointer;
    }
    .changeLogout {
      font-family: $HELVETICA;
      font-style: normal;
      font-weight: 700;
      font-size: $FONT_18;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $TEXT_RED;
      padding-left: 50px;
      padding-bottom: 30px;
      cursor: pointer;
    }
  }
  // .btn.custombtn {
  //   margin-top: 30px;
  // }
}

.changeOffcanvas {
  width: 550px !important;
  background: $BODY_BG !important;
  .offcanvas-header {
    padding: 36px;
    padding-bottom: 0px !important;
    .alignCenter {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
        cursor: pointer;
      }
      a {
        text-decoration: none;
      }
    }
  }
  .offcanvas-body {
    .blackBox {
      width: 100%;
      padding: 50px;
      padding-top: 10px !important;
      margin-top: 162px;

      h1 {
        font-size: $FONT_28;
        color: $TEXT_WHITE;
        font-family: $FONT_LOBSTER;
        font-style: normal;
        font-weight: 400;
        font-size: 57px;
        line-height: 64px;
        letter-spacing: -0.25px;
        width: 20px;
      }
      p {
        margin: 0 0 30px 0;
        font-weight: 400;
        line-height: 20px;
        font-size: $FONT_14;
        color: $TEXT_WHITE;
        font-family: $HELVETICA;
        font-style: normal;
        letter-spacing: 0.1px;
        width: 317px;
        max-width: 100%;
      }
      .custombtnfield {
        max-width: 105px;
        min-height: 40px;
        margin-top: 14px;
      }
    }
  }
}
.logoutModal {
  h4 {
    font-family: $HELVETICA;
    font-style: normal;
    font-weight: 700;
    font-size: $FONT_16;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    color: $TEXT_BLUE;
    margin: 16px;
  }
  p {
    font-family: $HELVETICA;
    font-style: normal;
    font-weight: 400;
    font-size: $FONT_12;
    line-height: 14px;
    text-align: center;
    color: $TEXT_BLUE;
    padding: 0 20px;
  }
  .btnInline {
    padding: 0px 12px !important;
    gap: 10px;
    margin: 0px 22px 16px 20.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .titleYes,
  .titleNo {
    &:focus-visible {
      outline: none;
    }

    &:active {
      background: $BG_ORANGE !important;
    }
  }
  .titleYes {
    font-family: $HELVETICA;
    font-style: normal;
    font-weight: 400;
    font-size: $FONT_12 !important;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #ffffff !important;
    box-shadow: none !important;
  }
  .titleNo {
    font-family: $HELVETICA;
    font-style: normal;
    font-weight: 400;
    font-size: $FONT_12 !important;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #ffffff !important;
    box-shadow: none !important;
    background: #c3c6cb !important;

    &:not(:disabled) {
      background-color: #c3c6cb;
    }
    &:not(.disabled):focus {
      background-color: $BG_ORANGE;
    }
    &:not(:disabled):not(.disabled):active {
      background: $BG_ORANGE;
    }
  }
}

@media only screen and (max-width: 810px) {
  .mainHeader {
    width: 115px;
  }
}
