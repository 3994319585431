@import "../../../assets/scss/variable.scss";

.navbar {
  position: absolute !important;
  width: 180px;
  padding: 0 !important;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100vh;
  flex-wrap: inherit !important;
  display: block !important;
  background-color: #033963;
  box-shadow: 4px 4px 4px rgb(0 0 0 / 25%);

    .navbar-logo {
      display: block !important;
      margin: 60px 6.78% 20px 6.78% !important;
      padding: 0 !important;

      img {
        max-width: 74%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.navbar-expand  {
      .navbar-collapse {
        display: block !important;
        margin: 0 17.78%;
        padding: 0 !important;
      }
    }
    
    .navbar-collapse {
      justify-content: center;
      align-items: center;

      .nav-link {
        position: relative;
        display: block;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        margin: 8px 0;
        padding: 12.08% 0;
        text-align: center;
        font-family: $HELVETICA;
        color: $TEXT_WHITE !important;
        font-style: normal;
        font-weight: 700;
        font-size: $FONT_12;
        background: $OFFSET_BG;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);

        &.active {
          background: $BG_ORANGE;
          box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
        }

        i {
          font-size: $FONT_22;
        }

        span {
          display: block;
        }

      }
    }
}

@media only screen and (max-width: 810px) {
  .navbar {
    width: 115px;

    .navbar-logo {
      margin: 60px 0.78% 15px!important;
    }

    .navbar.navbar-expand {

      .navbar-collapse {
        display: block!important;
        margin: 0 11.78%;
        padding: 0!important;
        }
      }
  }
}