@import "../../../assets/scss/variable.scss";

.signinMain {
  width: 100%;
  float: left;
  overflow: hidden;
  position: relative;

  .logoBack {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .signinLeft {
    width: 50%;
    float: left;
    height: 100%;
    position: relative;
    z-index: 9;

    .leftLogo {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      .logo {
        max-width: 90%;
        height: auto;
        display: block;
        margin: 24px auto;
      }

      .titlelogo {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0px auto 36px auto;
      }

      .lastlogo {
        max-width: 100%;
        height: auto;
        display: block;
        margin: auto auto 9px auto;
      }
    }
  }
  .signinRight {
    width: 50%;
    padding: 0 16px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BODY_BG;
    position: relative;
    z-index: 9;

    .whiteBox {
      width: 475px;
      max-width: 98%;
      margin-left: auto;
      margin-right: auto;
      // background-color: $BG_WHITE;
      border-radius: 24px;
      padding: 50px;
      .logo {
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 100px;
      }
      .alignCenter {
        i {
          font-size: $FONT_38;
          color: $TEXT_WHITE;
          font-weight: 400;
        }
        a {
          text-decoration: none;
        }
      }
      h1 {
        margin: auto 0;
        font-size: $FONT_57;
        color: $TEXT_WHITE;
        float: left;
        font-weight: 400;
        font-family: $FONT_LOBSTER;
        letter-spacing: -0.25px;
      }
      p {
        width: 100%;
        max-width: 100%;
        font-size: $FONT_14;
        color: $TEXT_WHITE;
        letter-spacing: 0.1px;
        margin: auto auto 30px 0px;
      }

      .custombtnfield {
        max-width: 94px;
        min-height: 40px;
        margin-top: 14px;
      }
    }
  }
}
