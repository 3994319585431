@import "../../../assets/scss/variable.scss";

.forgotMain {
  width: 100%;
  float: left;
  overflow: hidden;
  position: relative;

  .logoBack {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .forgotLeft {
    width: 50%;
    float: left;
    height: 100%;
    position: relative;
    z-index: 9;
  }
  .leftLogo {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    .logo {
      max-width: 90%;
      height: auto;
      display: block;
      margin: 24px auto;
    }

    .titlelogo {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0px auto 36px auto;
    }

    .lastlogo {
      max-width: 100%;
      height: auto;
      display: block;
      margin: auto auto 9px auto;
    }
  }
}
.forgotRight {
  width: 50%;
  padding: 0 16px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $BODY_BG;
  position: relative;
  z-index: 9;

  .whiteBox {
    width: 475px;
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
    // background-color: $BG_WHITE;
    border-radius: 24px;
    padding: 50px;

    .logo {
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 100px;
    }
    .alignCenter {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
      }
      a {
        text-decoration: none;
      }
    }
    h1 {
      font-family: $FONT_LOBSTER;
      text-align: left;
      margin: 61px 0 16px 0;
      font-size: $FONT_57;
      color: $TEXT_WHITE;
      font-weight: 400;
      letter-spacing: -0.25px;
      width: 100%;
      max-width: 50%;
    }
    p {
      max-width: 84%;
      // margin: 0 auto 36px auto;
      float: left;
      font-size: $FONT_12;
      color: $TEXT_WHITE;
    }
    .custombtnfield {
      max-width: 105px;
      min-height: 40px;
      margin-top: 14px;
    }
  }
}
