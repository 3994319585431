@import "../../../assets/scss/variable.scss";

.bookmark_main {
    background-image: url(https://res.cloudinary.com/zudu/image/upload/v1657710591/Birlinn/App/Project-images/MainBg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    padding: 0 2.5%;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #66000000;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $SCROLLBAR_BG;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #c0c0c0;
    }

    .books {

        .title {
            font-family: $FONT_LOBSTER;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_36;
            line-height: 44px;
            letter-spacing: -1.5px;
            color: #FFFFFF;
        }

        .selected_books {
            height: 170px;
            overflow: hidden;

            .recent_books_cards {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
                box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                padding: 10px;
                width: 24%;
                float: left;
                margin-right: 1%;
                margin-bottom: 1%;
                cursor: pointer;

                .book_card {
                    display: flex;

                    .left_side {

                        img {
                            width: 100px;
                            height: 140px;
                        }
                    }

                    .right_side {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        .contain {
                            margin-left: 10px;

                            p.date {
                                margin: 0;
                                font-family: $HELVETICA;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                color: $TEXT_BLUE;
                            }

                            h3.book_name {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-weight: 700;
                                font-size: $FONT_18;
                                line-height: 18px;
                                letter-spacing: 0.15px;
                                color: $TEXT_BLUE;
                            }

                            h4.book_author {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                color: $TEXT_BLUE;
                            }

                            h4.novel_name {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_14;
                                line-height: 16px;
                                letter-spacing: 0.25px;
                                color: $TEXT_BLUE;
                            }

                            h3.region_name {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_12;
                                line-height: 14px;
                                letter-spacing: 0.08em;
                                text-transform: uppercase;
                                color: $TEXT_DARK_BLUE_DARK;
                            }
                        }

                        .bookmark_events {

                            span.icon-heart-fill {
                                font-size: $FONT_24;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            &::after {
                content: "";
                clear: both;
                display: block;
            }
        }

        .selected_books.show_More {
            height: auto !important;
        }

        .more_less {
            text-align: end;
            margin-right: 8px;

            button.more_less_book_btn {
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 700;
                font-size: $FONT_18;
                line-height: 18px;
                align-items: center;
                text-align: right;
                letter-spacing: 0.15px;
                color: $TEXT_WHITE;
                background: none;
                border: none;
            }
        }
    }

    .no_data {
        font-family: "Lobster";
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 42px;
        color: $TEXT_WHITE;
        width: 100%;
        text-align: center;
        padding-top: 50px;
    }

    .shops {

        .title {
            font-family: $FONT_LOBSTER;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_36;
            line-height: 44px;
            letter-spacing: -1.5px;
            color: #FFFFFF;
        }

        .selected_shops {
            height: 170px;
            overflow: hidden;

            .shop_cards {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
                box-shadow: 4px 4px 0px rgb(0 0 0 / 25%);
                border-radius: 8px;
                padding: 10px;
                width: 24%;
                float: left;
                margin-right: 1%;
                margin-bottom: 1%;
                cursor: pointer;

                .contain_area {
                    display: flex;
                    position: relative;
                    justify-content: space-between;

                    .main_contain {
                        display: flex;
                        padding: 0;

                        .shop_img {

                            img {
                                width: 100%;
                                max-width: 120px;
                                min-height: 140px;
                            }
                        }

                        .shop_text {
                            margin-left: 10px;

                            .time {

                                p {
                                    font-family: $HELVETICA;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: $FONT_12;
                                    line-height: 14px;
                                    color: $TEXT_BLUE;
                                    margin: 0;
                                }
                            }

                            .shop_name {

                                p {
                                    font-family: $HELVETICA;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: $FONT_18;
                                    line-height: 18px;
                                    letter-spacing: 0.15px;
                                    color: $TEXT_BLUE;
                                    margin: 0;
                                    padding-top: 10px;
                                }
                            }

                            .region_name {

                                p {
                                    font-family: $HELVETICA;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: $FONT_12;
                                    line-height: 14px;
                                    display: flex;
                                    align-items: center;
                                    letter-spacing: 0.08em;
                                    text-transform: uppercase;
                                    margin: 0;
                                    padding-top: 10px;
                                }
                            }
                        }
                    }

                    .bookmark_events {

                        span.icon-heart-fill {
                            font-size: 24px;
                            color: $TEXT_WHITE;
                            cursor: pointer;
                        }
                    }
                }
            }

            &::after {
                content: "";
                clear: both;
                display: block;
            }
        }

        .selected_shops.show_More {
            height: auto !important;
        }

        .more_less {
            text-align: end;
            margin-right: 8px;

            button.more_less_shop_btn {
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 700;
                font-size: $FONT_18;
                line-height: 18px;
                align-items: center;
                text-align: right;
                letter-spacing: 0.15px;
                color: #FFFFFF;
                background: none;
                border: none;
            }
        }

    }

    .events {

        .title {
            font-family: $FONT_LOBSTER;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_36;
            line-height: 44px;
            letter-spacing: -1.5px;
            color: #FFFFFF;
        }

        .selected_events {
            height: 275px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;

            .Recent_events_card {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
                box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                width: 24%;
                float: left;
                margin-right: 1%;
                margin-bottom: 1%;
                padding: 10px;
                cursor: pointer;


                .top_side {

                    img {
                        width: 100%;
                        height: 180px;
                        min-height: 178.44px;
                        object-fit: cover;
                        object-position: top;
                    }
                }

                .down_side {

                    .name_bookmark {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        padding-bottom: 14px;

                        .event_name {

                            h4 {
                                font-family: $HELVETICA;
                                font-weight: 700;
                                font-size: $FONT_18;
                                line-height: 18px;
                                letter-spacing: 0.15px;
                                color: $TEXT_BLUE;
                                margin: 0;
                            }
                        }

                        .bookmark_events {

                            span.icon-fav {
                                font-size: 24px;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }

                            span.icon-heart-fill {
                                font-size: 24px;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }
                        }
                    }

                    .region_month {
                        display: flex;

                        .region_name {
                            width: 70%;

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_12;
                                line-height: 14px;
                                letter-spacing: 0.08em;
                                text-transform: uppercase;
                                color: $TEXT_DARK_BLUE_DARK;
                                margin: 0;
                            }
                        }

                        .month_name {
                            width: 30%;
                            display: flex;
                            justify-content: end;

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                display: flex;
                                align-items: center;
                                color: $TEXT_BLUE;
                                margin: 0;
                            }
                        }
                    }
                }

            }

            &::after {
                content: "";
                clear: both;
                display: block;
            }
        }

        .selected_events.show_More {
            height: auto !important;
        }

        .more_less {
            text-align: end;
            margin-right: 8px;

            button.more_less_event_btn {
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 700;
                font-size: $FONT_18;
                line-height: 18px;
                align-items: center;
                text-align: right;
                letter-spacing: 0.15px;
                color: #FFFFFF;
                background: none;
                border: none;
            }
        }
    }
}

// @media only screen and (max-width: 1865px) {
//     .bookmark_main .events .selected_events {
//         height: 260px;
//     }
// }

// @media only screen and (max-width: 1677px) {
//     .bookmark_main .events .selected_events {
//         height: 255px;
//     }
// }

@media only screen and (max-width: 1650px) {
    .bookmark_main .books .selected_books .recent_books_cards {
        width: 32%;
    }

    .bookmark_main .shops .selected_shops .shop_cards {
        width: 32%;
    }

    .bookmark_main .events .selected_events .Recent_events_card {
        width: 32%;
    }

    // .bookmark_main .events .selected_events {
    //     height: 295px;
    // }
    .bookmark_main .books .more_less {
        margin-right: 19px;
    }

    .bookmark_main .shops .more_less {
        margin-right: 19px;
    }

    .bookmark_main .events .more_less {
        margin-right: 19px;
    }
}

// @media only screen and (max-width: 1600px) {
//     .bookmark_main .events .selected_events {
//         height: 285px;
//     }
// }

// @media only screen and (max-width: 1535px) {
//     .bookmark_main .events .selected_events {
//         height: 275px;
//     }
// }

// @media only screen and (max-width: 1470px) {
//     .bookmark_main .events .selected_events {
//         height: 265px;
//     }
// }

// @media only screen and (max-width: 1405px) {
//     .bookmark_main .events .selected_events {
//         height: 260px;
//     }
// }


@media only screen and (max-width: 1400px) {
    .bookmark_main .books .selected_books .recent_books_cards {
        width: 49%;
    }

    .bookmark_main .shops .selected_shops .shop_cards {
        width: 49%;
    }

    .bookmark_main .events .selected_events .Recent_events_card {
        width: 49%;
    }

    .bookmark_main .books .more_less {
        margin-right: 2px;
    }

    .bookmark_main .shops .more_less {
        margin-right: 2px;
    }

    .bookmark_main .events .more_less {
        margin-right: 2px;
    }

    // .bookmark_main .events .selected_events {
    //     height: 350px;
    // }
}

// @media only screen and (max-width: 1370px) {
//     .bookmark_main .events .selected_events {
//         height: 342px;
//     }
// }

// @media only screen and (max-width: 1335px) {
//     .bookmark_main .events .selected_events {
//         height: 332px;
//     }
// }

// @media only screen and (max-width: 1291px) {
//     .bookmark_main .events .selected_events {
//         height: 322px;
//     }
// }

// @media only screen and (max-width: 1247px) {
//     .bookmark_main .events .selected_events {
//         height: 311px;
//     }
// }

// @media only screen and (max-width: 1200px) {
//     .bookmark_main .events .selected_events {
//         height: 301px;
//     }
// }

@media only screen and (max-width: 1190px) {
    .bookmark_main .books .selected_books {
        height: 166px;
        overflow: hidden;
    }

    .bookmark_main .shops .selected_shops {
        height: 166px;
        overflow: hidden;
    }

    .bookmark_main .events .selected_events {
        height: 353px;
        overflow: hidden;
    }
}