@import "../../../../assets/scss/variable.scss";

.toast {
  position: fixed;
  top: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 9999;
  text-align: center;
  flex-basis: inherit !important;
  margin: 0 auto !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 16px;
  display: none;
  width: inherit !important;

  .tostcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    width: 250px;
    background: $BG_WHITE;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    -ms-box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    -o-box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;

    p {
      font-weight: 400;
      text-align: center;
      margin: 6px 0 0 0;
      font-family: $HELVETICA;
      font-size: $FONT_12;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 14px;
      &.successTag {
        color: $TEXT_BLUE;
      }
      &.errorTag {
        color: $TEXT_BLUE;
      }
    }
    i {
      font-size: $FONT_24;
    }

    .icon-check-circle {
      color: $TEXT_SUCCESS;
    }

    .icon-info1 {
      color: $TEXT_RED;
    }
  }

  &.show {
    display: block;
  }
}
