@import "../../../../assets/scss/variable.scss";

.checkboxMain {
  display: block;
}

.checkboxMain input {
  padding: 5px;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkboxMain label {
  position: relative;
  cursor: pointer;
  font-size: $FONT_14;
  color: $TEXT_WHITE;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-square {
  cursor: pointer;
  padding: 6px;
  width: 25px;
  height: 25px;
}
.icon-check-square-top {
  padding: 6px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.checkedIcon {
  padding: 6px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}
