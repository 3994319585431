.main {
  .logoBack {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.usermain {
  height: 100%;
  padding-left: 180px;
  overflow: hidden;
}

@media only screen and (max-width: 810px) {
  .usermain {
    padding-left: 115px;
  }
}
