@import "../../../assets/scss/variable.scss";

.loginMain {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;

  .logoBack {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .loginLeft {
    width: 50%;
    float: left;
    height: 100%;
    position: relative;
    z-index: 9;

    .leftLogo {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      .logo {
        max-width: 90%;
        height: auto;
        display: block;
        margin: 24px auto;
      }

      .titlelogo {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0px auto 36px auto;
      }

      .lastlogo {
        max-width: 100%;
        height: auto;
        display: block;
        margin: auto auto 9px auto;
      }
    }
  }

  .loginRight {
    width: 50%;
    padding: 0 16px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $BODY_BG;
    position: relative;
    z-index: 9;

    .whiteBox {
      width: 375px;
      max-width: 99%;
      margin-left: auto;
      margin-right: auto;

      h1 {
        margin: 36px 0 16px 0;
        font-size: $FONT_57;
        color: $TEXT_WHITE;
        font-weight: 400;
        font-family: $FONT_LOBSTER;
        letter-spacing: -0.25px;
      }
      p {
        margin: 0 0 30px 0;
        font-weight: 400;
        line-height: 20px;
        font-size: $FONT_12;
        color: $TEXT_WHITE;
        font-family: $HELVETICA;
        font-style: normal;
        letter-spacing: 0.1px;
        width: 290px;
        max-width: 100%;
      }

      .checkBoxInline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        float: left;
        margin-bottom: 30px;

        .checkboxMain {
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.25px;
        }

        a {
          font-family: $HELVETICA;
          font-size: $FONT_14;
          color: $TEXT_WHITE;
          text-decoration: none;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.25px;
        }
      }

      .custombtnfield {
        max-width: 94px;
        min-height: 42px;
        padding: 8px 24px;
      }
    }
  }
  .setpara {
    p {
      width: 100%;
      max-width: 69%;
    }
  }
}

@media (max-width: 768px) and (max-width: 1024px) {
  .loginMain {
    max-width: 100%;
    height: auto;
    .setpara {
      p {
        max-width: 100%;
      }
    }
    .loginRight {
      .whiteBox {
        width: 275px;
        .alignCenter {
          a {
            position: absolute;
            right: 9%;
          }
        }
        // p{
        //   font-size: $FONT_16;
        // }
      }
    }
  }
}
