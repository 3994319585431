@import "../../assets/scss/variable.scss";

.goBackMainPage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  float: left;
  .goBackLogo {
    text-align: center;
    img {
      width: 100%;
      max-width: 300px;
      object-fit: contain;
    }
  }
  .goBackHeading {
    font-family: $FONT_LOBSTER;
    font-weight: 400;
    font-size: $FONT_57;
    line-height: 84px;
    text-align: center;
    letter-spacing: -0.25px;
    color: $TEXT_BLUE;
    margin-bottom: 18px;
  }
  .goBackText {
    font-family: $HELVETICA;
    font-weight: 400;
    font-size: $FONT_14;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: $TEXT_DISABLE;
    margin-bottom: 18px;
  }
  .goBackButton {
    text-align: center;

    .goBackButton-btn {
      justify-content: center;
      padding: 8px 24px;
      gap: 10px;
      width: 160px;
      height: 42px;
      border: none;
      background: $BG_ORANGE;
      box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
      font-family: $HELVETICA;
      font-weight: 700;
      font-size: $FONT_16;
      line-height: 18px;
      letter-spacing: 0.5px;
      color: $TEXT_WHITE;
    }
  }
}
@media only screen and (max-width: 768px) {
  .goBackMainPage {
    width: 100%;
    .goBackHeading {
      font-size: $FONT_50;
    }
  }
}
