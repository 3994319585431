@import "../../../../assets/scss/variable.scss";

.labelTitle {
  margin: 0;
  font-size: $FONT_24;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .labelTitle {
    font-size: $FONT_20;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
  .labelTitle {
    font-size: $FONT_22;
  }
}