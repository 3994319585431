@import "../../../../assets/scss/variable.scss";

.textField {
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 16px;
  float: left;

  .form-control {
    background: $BG_WHITE;
    color: $TEXT_BLUE !important;
    font-size: $FONT_12;
    padding: 6px 16px 6px 40px;
    width: 100%;
    height: 40px;
    border-radius: 0px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-weight: 400;
    font-family: $HELVETICA;

    &:disabled {
      cursor: not-allowed;
      color: $BG_WHITE;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.error {
      border: 2px solid $BG_RED;
    }

    &::placeholder {
      color: $TEXT_PLACEHOLDER;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $TEXT_PLACEHOLDER;
    }

    &::-ms-input-placeholder {
      color: $TEXT_PLACEHOLDER;
    }
  }

  .iconRight {
    padding-right: 40px;
  }

  .iconLeft {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $FONT_20;
    color: $TEXT_BLUE;
  }

  .iconSection {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: $FONT_20;
    color: $TEXT_LIGHT_GRAY;
    i {
      padding: 3px;
      cursor: pointer;
    }
  }

  .errorMsg {
    position: relative;
    font-family: $HELVETICA;
    width: 100%;
    left: 0;
    display: block;
    font-size: $FONT_14;
    text-align: left;
    padding: 0;
    margin: 0;
    overflow: hidden;
    max-height: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-shadow: 0.5px 0.5px 0px $BG_BLACK;
    transition: max-height 0.3s ease-out;
  }

  .errorMsg.show {
    max-height: 130px;
    transition: max-height 0.3s ease-in;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    color: $TEXT_RED;
    span {
      padding: 6px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      i {
        font-size: $FONT_16;
        margin-right: 4px;
      }
    }
    .red,
    .green {
      display: flex;
      padding: 2px 0;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }

    .red {
      span {
        color: $TEXT_RED;
      }
    }

    .green {
      span {
        color: $TEXT_GREEN;
      }
    }
  }
}
