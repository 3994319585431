@import "../../../assets/scss/variable.scss";

.mainarea_mapsidebar {
    background: $BODY_BG;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
    // overflow-y: auto;
    overflow: hidden;

    .titlebar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2.892% 4.82% 0;
        position: relative;

        .title_text {
            font-family: 'Lobster';
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_37;
            line-height: 42px;
            color: $BG_WHITE;
            width: 100%;
            text-align: center;
        }
    }

    .main_contain_data {
        padding: 2.892% 4.82% 0;
        overflow-y: scroll;
        padding-bottom: 50px;

        /* width */
      &::-webkit-scrollbar {
        width: 6px;
      }
  
      /* Track */
      &::-webkit-scrollbar-track {
        background: #66000000;
      }
  
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $SCROLLBAR_BG;
        border-radius: 10px;
      }
  
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #c0c0c0;
      }

        .recent_books_cards {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
            box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            padding: 10px;
            width: 399px;
            max-width: 100%;
            margin-bottom: 10px;

            .book_card {
                display: flex;
                position: relative;
                
                .left_side {
                    img {
                        width: 90px;
                        height: 100%;
                        min-height: 120px;
                    }
                }
                .right_side {
                    display: flex;

                    .contain {
                        margin-left: 10px;

                        p.date {
                            margin: 0;
                            font-family: $HELVETICA;
                            font-weight: 400;
                            font-size: $FONT_12;
                            line-height: 14px;
                            color: $TEXT_BLUE;
                        }
                        h3.book_name {
                            margin: 10px 0 0 0;
                            font-family: $HELVETICA;
                            font-weight: 700;
                            font-size: $FONT_18;
                            line-height: 18px;
                            letter-spacing: 0.15px;
                            color: $TEXT_BLUE;   
                        }
                        h4.book_author {
                            margin: 10px 0 0 0;
                            font-family: $HELVETICA;
                            font-style: normal;
                            font-weight: 400;
                            font-size: $FONT_12;
                            line-height: 14px;
                            color: $TEXT_BLUE;
                        }
                        h4.novel_name {
                            margin: 10px 0 0 0;
                            font-family: $HELVETICA;
                            font-style: normal;
                            font-weight: 400;
                            font-size: $FONT_14;
                            line-height: 16px;
                            letter-spacing: 0.25px;
                            color: $TEXT_BLUE;
                        }
                        h3.region_name {
                            margin: 10px 0 0 0;
                            font-family: $HELVETICA;
                            font-style: normal;
                            font-weight: 700;
                            font-size: $FONT_12;
                            line-height: 14px;
                            letter-spacing: 0.08em;
                            text-transform: uppercase;
                            color: $TEXT_DARK_BLUE_DARK;
                        }
                    }

                    .bookmark_area {
                        position: absolute;
                        right: 0;

                        a {
                            font-size: $FONT_21;
                            text-decoration: none;
                            color: $TEXT_WHITE;
                        }
                    }
                }
            }
        }

        .Recent_events_card {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
            box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            max-width: 100%;
            width: 399px;
            margin-bottom: 10px;

            .event_card {
                padding: 10px;

                .top_side {

                    img {
                        width: 100%;
                    }
                }

                .down_side {

                    .name_bookmark {
                        display: flex;
                        position: relative;
                        margin-top: 10px;
                        padding-bottom: 14px;

                        .event_name {

                            h4 {
                                font-family: $HELVETICA;
                                font-weight: 700;
                                font-size: $FONT_18;
                                line-height: 18px;
                                letter-spacing: 0.15px;
                                color: $TEXT_BLUE;
                                margin: 0;
                            }
                        }

                        .bookmark_events {

                            a {
                                text-decoration: none;
                                position: absolute;
                                right: 0;
                                font-size: $FONT_21;
                                color: $TEXT_WHITE;
                                top: 3px;
                            }
                        }
                    }

                    .region_month {
                        display: flex;

                        .region_name {
                            width: 70%;

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_12;
                                line-height: 14px;
                                letter-spacing: 0.08em;
                                text-transform: uppercase;
                                color: $TEXT_DARK_BLUE_DARK;
                                margin: 0;
                            }
                        }

                        .month_name {
                            width: 30%;
                            display: flex;
                            justify-content: end;

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                display: flex;
                                align-items: center;
                                color: $TEXT_BLUE;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .shop_cards {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
            box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            padding: 10px;
            gap: 10px;
            max-width: 100%;
            width: 399px;
            margin-bottom: 10px;

            .contain_area {
                display: flex;
                position: relative;

                .main_contain {
                    display: flex;
                    padding: 0;

                    .shop_text {
                        margin-left: 10px;

                        .time {

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                color: $TEXT_BLUE;
                                margin: 0;
                            }
                        }

                        .shop_name {

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_18;
                                line-height: 18px;
                                letter-spacing: 0.15px;
                                color: $TEXT_BLUE;
                                margin: 0;
                                padding-top: 10px;
                            }
                        }

                        .region_name {

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_12;
                                line-height: 14px;
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.08em;
                                text-transform: uppercase;
                                margin: 0;
                                padding-top: 10px;
                            }
                        }
                    }
                }

                .shop_bookmark {

                    a {
                        text-decoration: none;
                        color: $TEXT_WHITE;
                        font-size: $FONT_24;
                        position: absolute;
                        right: 0;
                        top: -5px;
                    }
                }
            }
        }
    }
}