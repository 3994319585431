@import "../../../../assets/scss/variable.scss";

.RadioButton {
  position: relative;

  input[type="radio"] {
    position: absolute;
    left: 0px;
    top: 0;
    opacity: 0;
    pointer-events: none;

    + label {
      margin: 0;
      font-size: $FONT_14;
      font-weight: normal;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $TEXT_WHITE;
      min-width: 14px;
      padding-left:20px;
      text-transform: capitalize;

      &:before {
        content: "";
        // background: red;
        border-radius: 16px;
        display: flex;
        align-items: center;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 3px;
        left: 0;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
        border: 2px solid $TEXT_WHITE;
        background-color: transparent;
      }
    }
    &:checked {
      + label {
        &::before{
          background-color: $TEXT_WHITE;
        }
      }
    }

    &:disabled {
      + label {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
