@import "../../../../assets/scss/variable.scss";

.tabDropdown {
  width: 100%;
  max-width: 290px;
  .label {
    font-size: $FONT_16;
    color: $TEXT_DISABLE;
    margin-bottom: 8px;
    font-weight: 700;
    span {
      font-size: $FONT_14;
      // color: $TEXT_RED;
    }
  }
  .dropdown {
    width: 100%;
    text-align: left;
    // margin-bottom: 16px;

    .btn {
      background: $BG_WHITE !important;
      font-size: $FONT_12 !important;
      padding: 11px 40px 11px 20px !important;
      width: 100% !important;
      border-radius: 0px !important;
      border: none !important;
      // border: 1px solid $BG_BLUE !important;
      outline: none !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      box-shadow: none !important;
      font-weight: 400 !important;
      color: $TEXT_PLACEHOLDER !important;
      font-family: "Helvetica" !important;
      text-transform: none !important;
      text-align: left;
      line-height: 18px;

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
      &::after {
        display: none;
      }
      &:active {
        border: none !important;
      }
    }

    .dropdown-toggle {
      content: "\e907" !important;
    }

    &.dropdown {
      &::after {
        margin: 0;
        content: "\e907" !important;
        font-family: "Birlinn" !important;
        border: none !important;
        font-size: $FONT_18;
        position: absolute;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 0;
        top: 0;
        cursor: pointer;
        box-shadow: none !important;
      }
    }

    .dropdown-menu {
      padding: 0;
      background-color: $BG_WHITE;
      width: 100%;
      overflow: hidden;

      .dropdownData {
        .dropdown-item {
          font-weight: 500 !important;
          font-family: "Helvetica" !important;
          padding: 12px 20px;

          &:hover {
            text-decoration: none;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    &.selected {
      .btn {
        font-weight: 700 !important;
      }
    }
  }

  .selected {
    &.dropdown {
      .btn-primary {
        color: $TEXT_DARK_BLUE !important;
      }
    }
  }
}
