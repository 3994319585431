@import "../../../assets/scss/variable.scss";

.tourscreen_area {
  background-image: url("https://res.cloudinary.com/zudu/image/upload/v1656320757/Birlinn/App/Project-images/MainBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  .contain_area {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    max-width: 1024px;
    position: relative;
    padding: 55.95px 0 40px 0;

    .logoarea {
      text-align: center;
      padding: 0 0 56px;

      img.logo {
        width: 100%;
        max-width: 275.4px;
      }
    }

    .textarea {
      max-width: 768px;
      margin: auto;
      background: linear-gradient(180deg,
          rgba(3, 57, 99, 0.035) 0%,
          rgba(3, 57, 99, 0.5) 55%,
          rgba(3, 57, 99, 0) 145.63%);
      opacity: 0.8;
      padding: 20px;
      overflow-y: auto;

      /* width */
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #66000000;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $SCROLLBAR_BG;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #c0c0c0;
      }

      p {
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_16;
        line-height: 24px;
        text-align: justify;
        letter-spacing: 0.5px;
        color: $BG_WHITE;
        margin: 0;
      }

      p:first-child {
        padding-bottom: 16px;
      }

      p:nth-child(2) {
        padding-top: 16px;
      }
    }

    .d-grid.gap-2 {
      text-align: center;
      padding: 56px 0 0 0;
      display: block !important;
    }

    .bottom {
      text-align: center;
      padding: 56px 0 0 0;

      .logo {
        max-width: 248.14px;
        width: 100%;
      }
    }

    .ship1 {
      position: absolute;
      max-width: 128.55px;
      width: 100%;
      top: 127px;
      left: 0;
    }

    .tower {
      max-width: 63.81px;
      width: 100%;
      position: absolute;
      top: 75.87px;
      right: 46px;
    }

    .fish {
      max-width: 56.91px;
      width: 100%;
      position: absolute;
      bottom: 96px;
      left: 143px;
    }

    .ship2 {
      max-width: 59.09px;
      width: 100%;
      position: absolute;
      bottom: 80px;
      right: 201.95px;
    }

    .castle {
      max-width: 100px;
      width: 100%;
      position: absolute;
      right: 10.44px;
      bottom: 45px;
    }
  }

  .btn_tour {
    font-family: $HELVETICA;
    font-style: normal;
    font-weight: 700;
    font-size: $FONT_16;
    line-height: 18px;
    letter-spacing: 0.5px;
    padding: 8px 24px;
    background-color: $BG_ORANGE;
    box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
    border: none;
    color: $BG_WHITE;
    text-decoration: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .tourscreen_area {
    .contain_area {
      padding: 45.95px 0 30px 0;

      .logoarea {
        padding: 0 0 36px;

        .logo {
          max-width: 200px !important;
        }
      }

      .textarea {
        max-width: 600px;
      }

      .d-grid.gap-2 {
        padding: 36px 0 0 0;

        .btn_tour {
          font-size: $FONT_14;
        }
      }

      .bottom {
        padding: 36px 0 0 0;

        .logo {
          max-width: 200px;
        }
      }

      .ship1 {
        max-width: 118.55px;
        top: 97px;
        left: 20px;
      }

      .tower {
        max-width: 53.81px;
        top: 58px;
      }

      .castle {
        max-width: 90px;
        right: 20px;
        bottom: 20px;
      }

      .ship2 {
        max-width: 49.09px;
        bottom: 50px;
        right: 180.95px;
      }

      .fish {
        max-width: 46.91px;
        bottom: 66px;
        left: 73px;
      }
    }
  }
}