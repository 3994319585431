@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import "./assets/scss/variable.scss";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-credentials-auto-fill-button {
  background-color: white;
}

body {
  position: relative;
  // background-color: $BODY_BG !important;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Helvetica" !important;
  font-size: $FONT_14;
}

@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/Helvetica-Light.eot");
  src: url("./assets/fonts/Helvetica-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Helvetica-Light.woff2") format("woff2"),
    url("./assets/fonts/Helvetica-Light.woff") format("woff"),
    url("./assets/fonts/Helvetica-Light.ttf") format("truetype"),
    url("./assets/fonts/Helvetica-Light.svg#Helvetica-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/Helvetica.eot");
  src: url("./assets/fonts/Helvetica.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Helvetica.woff2") format("woff2"),
    url("./assets/fonts/Helvetica.woff") format("woff"),
    url("./assets/fonts/Helvetica.ttf") format("truetype"),
    url("./assets/fonts/Helvetica.svg#Helvetica") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Birlinn";
  src: url("./assets/fonts/Birlinn.eot?51z1gj");
  src: url("./assets/fonts/Birlinn.eot?51z1gj#iefix") format("embedded-opentype"),
    url("./assets/fonts/Birlinn.ttf?51z1gj") format("truetype"),
    url("./assets/fonts/Birlinn.woff?51z1gj") format("woff"),
    url("./assets/fonts/Birlinn.svg?51z1gj#Birlinn") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Birlinn" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-underline:before {
  content: "\e900";
}

.icon-search:before {
  content: "\e901";
}

.icon-alignCenter:before {
  content: "\e902";
}

.icon-events:before {
  content: "\e903";
}

.icon-edit-2:before {
  content: "\e904";
}

.icon-shop:before {
  content: "\e905";
}

.icon-music:before {
  content: "\e906";
}

.icon-chevron-down:before {
  content: "\e907";
}

.icon-cancel:before {
  content: "\e908";
}

.icon-map:before {
  content: "\e909";
}

.icon-books:before {
  content: "\e90a";
}

.icon-x:before {
  content: "\e90b";
}

.icon-lock:before {
  content: "\e90c";
}

.icon-log-in:before {
  content: "\e90d";
}

.icon-pin:before {
  content: "\e90e";
}

.icon-upload-1:before {
  content: "\e90f";
}

.icon-eye-off:before {
  content: "\e910";
}

.icon-bold:before {
  content: "\e911";
}

.icon-alignLeft:before {
  content: "\e912";
}

.icon-headphones:before {
  content: "\e913";
}

.icon-info:before {
  content: "\e914";
}

.icon-insertImage:before {
  content: "\e915";
}

.icon-check-circle:before {
  content: "\e916";
}

.icon-fav:before {
  content: "\e917";
}

.icon-camera:before {
  content: "\e918";
}

.icon-insertLink:before {
  content: "\e919";
}

.icon-cloud-off:before {
  content: "\e91a";
}

.icon-edit:before {
  content: "\e91b";
}

.icon-orderedList:before {
  content: "\e91c";
}

.icon-trash-2:before {
  content: "\e91d";
}

.icon-file-plus:before {
  content: "\e91e";
}

.icon-clock:before {
  content: "\e91f";
}

.icon-eye:before {
  content: "\e920";
}

.icon-more-vertical:before {
  content: "\e921";
}

.icon-italic:before {
  content: "\e922";
}

.icon-calendar:before {
  content: "\e923";
}

.icon-arrow-left:before {
  content: "\e924";
}

.icon-minus-circle:before {
  content: "\e925";
}

.icon-upload:before {
  content: "\e926";
}

.icon-chevron-left:before {
  content: "\e927";
}

.icon-info1:before {
  content: "\e928";
}

.icon-user:before {
  content: "\e929";
}

.icon-square-1:before {
  content: "\e92a";
}

.icon-square:before {
  content: "\e92b";
}

.icon-check-square-top:before {
  content: "\e92c";
}

.icon-bookmark:before {
  content: "\e92d";
}

.icon-filtter:before {
  content: "\e92e";
}

.icon-corner-up-right:before {
  content: "\e92f";
}

.icon-globe:before {
  content: "\e930";
}

.icon-mail:before {
  content: "\e931";
}

.icon-phone:before {
  content: "\e932";
}

.icon-heart-fill:before {
  content: "\e933";
}

.icon-coolicon:before {
  content: "\e934";
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.48);
  z-index: 999;

  .steps {
    .box {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .activeNav {
        width: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: $TEXT_WHITE;
        font-size: 14px;
        background: #f7a637;
        box-shadow: 4px 4px 0px #ee7212;
        padding: 9px 0;
        width: 115px;
        border: 2px solid $BG_WHITE;

        i {
          font-size: $FONT_22;
          margin-bottom: 10px;
        }
      }

      .titleWithArrow {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 70px;
        margin-top: -50px;

        img {
          position: relative;
          top: 20px;
          max-width: 100%;
          height: auto;
          display: block;
          margin-right: 28px;
        }

        .title {
          font-family: $FONT_LOBSTER;
          color: $TEXT_WHITE;
          font-size: $FONT_40;
          margin: 0;
          line-height: 42px;
        }
      }
    }
  }

  .firstStep {
    visibility: hidden;
    pointer-events: none;

    &.active {
      visibility: visible;
      pointer-events: auto;
    }

    .box {
      top: 151px;
      left: 32px;
    }

  }

  .secondStep {
    visibility: hidden;
    pointer-events: none;

    &.active {
      visibility: visible;
      pointer-events: auto;
    }

    .box {
      top: 240px;
      left: 32px;

      .activeNav {
        background: #29577a;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }

  }

  .thirdStep {
    visibility: hidden;
    pointer-events: none;

    &.active {
      visibility: visible;
      pointer-events: auto;
    }

    .box {
      top: 330px;
      left: 32px;

      .activeNav {
        background: #29577a;
        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }

  }

  .fourthStep {
    visibility: hidden;
    pointer-events: none;

    &.active {
      visibility: visible;
      pointer-events: auto;
    }

    .box {
      top: 250px;
      left: auto;
      right: 52%;

      .titleWithArrow {
        margin-left: 0;
        margin-top: 0;

        img {
          transform: rotate(-100deg);
          top: 0;
          margin-left: 33px;
        }
      }
    }

  }

  .buttonBottm {
    position: absolute;
    right: 150px;
    bottom: 150px;

    .next {
      width: auto;
      height: auto;
      font-family: $HELVETICA;
      font-weight: 700 !important;
      font-size: $FONT_22 !important;
      padding: 14px 30px;
      background-color: $BG_ORANGE;
      box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
      border: none;
      color: $BG_WHITE;
      text-decoration: none;
      margin-right: 30px;
    }

    .skip {
      width: auto;
      height: auto;
      font-family: $HELVETICA;
      font-weight: 700 !important;
      font-size: $FONT_22 !important;
      padding: 14px 30px;
      background-color: transparent;
      border: 1px solid $BG_ORANGE;
      color: $BG_WHITE;
      text-decoration: none;
      box-shadow: none;

      &:focus,
      &:hover {
        border: 1px solid $BG_ORANGE !important;
      }
    }
  }

}

@media (max-width: 810px) {
  .overlay {
    .steps {
      .box {
        .activeNav {
          font-size: 12px;
          padding: 6px 0;
          width: 75px;

          i {
            font-size: $FONT_18;
            margin-bottom: 10px;
          }
        }

        .titleWithArrow {
          margin-left: 40px;
          margin-top: -20px;

          img {
            top: 10px;
            width: 40px;
            margin-right: 20px;
          }

          .title {
            font-size: $FONT_26;
            line-height: 28px;
          }
        }
      }

    }

    .firstStep {
      .box {
        top: 132px;
        left: 20px;
      }
    }

    .secondStep {
      .box {
        top: 210px;
        left: 22px;
      }
    }

    .thirdStep {
      .box {
        top: 285px;
        left: 20px;
      }
    }

    .fourthStep {

      .box {
        top: 350px;
        left: auto;
        right: 40%;

        .titleWithArrow {
          margin-left: 0;
          margin-top: 0;

          img {
            transform: rotate(-100deg);
            top: 0;
            margin-left: 33px;
          }
        }
      }

    }

    .buttonBottm {
      right: 50px;
      bottom: 50px;

      .next {
        font-size: $FONT_16 !important;
        padding: 10px 20px;
        background-color: $BG_ORANGE;
        box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
        margin-right: 20px;
      }

      .skip {
        font-size: $FONT_16 !important;
        padding: 10px 20px;
      }
    }

  }
}