@import "../../../../assets/scss/variable.scss";

.searchBoxDiv {
  position: relative;

  .searchInput {
    width: 100%;
    border: 0;
    min-height: 40px;
    padding: 0px 36px 0px 40px !important;
    border-radius: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: $BG_WHITE;
    font-size: $FONT_14;
    color: $TEXT_BLACK;
    box-shadow: none;
    margin-bottom: 24px;

    &::-webkit-input-placeholder {
      color: $TEXT_PLACEHOLDER;
    }
    &::-moz-placeholder {
      color: $TEXT_PLACEHOLDER;
    }
    &:-ms-input-placeholder {
      color: $TEXT_PLACEHOLDER;
    }
    &:-moz-placeholder {
      color: $TEXT_PLACEHOLDER;
    }
    &:focus {
      outline: none;
    }
  }
  .icon-x {
    border: 0;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    position: absolute;
    right: 0 !important;
    top: 0;
    padding: 0px;
    cursor: pointer;
    // width: 45px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 15px;
  }

  // &:after {
  //   content: "\e9d2";
  //   font-family: "Birlinn" !important;
  //   display: block;
  //   position: relative;
  //   font-size: $FONT_16;
  //   color: $TEXT_BLACK;
  //   cursor: pointer;
  // }
}
.search-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $FONT_20;
  color: $TEXT_BLACK;
}
