@import "../../../../assets/scss/variable.scss";

.btn {
  &.custombtn {
    width: 100%;
    height: 100%;
    background: $BG_ORANGE;
    box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
    padding: 8px 24px;
    border: 1px solid transparent;
    font-size: $FONT_16 !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    border-radius: 0px !important;
    box-shadow: none;
    transition: none !important;
    cursor: pointer;
    font-family: $HELVETICA;
    letter-spacing: 0.5px;

    &:disabled {
      opacity: 1 !important;
      cursor: no-drop;
      padding: 8px 24px;
      background: $BTN_DISABLED;
      box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
      pointer-events: all;
      color: $BTN_TEXT_DISABLED !important;
      border: 1px solid transparent !important;
    }

    &:focus {
      border-color: transparent !important;
    }

    &:hover {
      border-color: transparent !important;
    }

    &:active {
      background: $BG_ORANGE;
      border-color: transparent !important;
      box-shadow: none !important;
    }
    &:not(:disabled) {
      background: $BG_ORANGE;
      box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
      color: $TEXT_WHITE;
    }

    &:not(:disabled):not(.disabled):active {
      // background: $BODY_BG;
    }

    &:not(.disabled):focus {
      // box-shadow: none !important;
      // background: $BODY_BG;
    }

    &.btn-loading {
      opacity: 1 !important;
      cursor: default;
    }
  }
}
