@import "../../../../assets/scss/variable.scss";

.modal {
  .modal-header {
    padding: 0;
    border: none;
  }

  .modal-dialog {
    width: 250px;
    height: 135px;
    max-width: 98%;
    margin: 6.75rem auto !important;

    .modal-content {
      width: 100%;
      margin: 0 auto;
      border: 0;
      border-radius: 0 !important;
      overflow: hidden;
      position: relative;
    }
  }
}
