@import "../../../assets/scss/variable.scss";

.directory_main {
    background-image: url(https://res.cloudinary.com/zudu/image/upload/v1657710591/Birlinn/App/Project-images/MainBg.png);
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    padding: 0 2.5%;
    width: 100%;
    padding-top: 24px;
    overflow: hidden;

    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #66000000;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $SCROLLBAR_BG;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #c0c0c0;
    }

    .categories {
        display: flex;
        gap: 24px;
        padding: 24px 0px;

        .books_card {
            position: relative;
            width: 33.33%;
            cursor: pointer;

            img {
                width: 100%;
                border-radius: 16px;
                min-height: 150px;
            }

            p.card_text {
                font-family: $HELVETICA;
                font-weight: 700;
                font-size: $FONT_32;
                line-height: 37px;
                color: $TEXT_WHITE;
                position: absolute;
                bottom: 20px;
                left: 16px;
                margin: 0;
                background: #20202099;
                border-radius: 10px;
                padding: 0 5px;
            }
        }

        .event_card {
            position: relative;
            width: 33.33%;
            cursor: pointer;

            img {
                width: 100%;
                border-radius: 16px;
                min-height: 150px;
            }

            p.card_text {
                font-family: $HELVETICA;
                font-weight: 700;
                font-size: $FONT_32;
                line-height: 37px;
                color: $TEXT_WHITE;
                position: absolute;
                bottom: 20px;
                left: 16px;
                margin: 0;
                background: #20202099;
                border-radius: 10px;
                padding: 0 5px;
            }
        }

        .shop_card {
            position: relative;
            width: 33.33%;
            cursor: pointer;

            img {
                width: 100%;
                border-radius: 16px;
                min-height: 150px;
            }

            p.card_text {
                font-family: $HELVETICA;
                font-weight: 700;
                font-size: $FONT_32;
                line-height: 37px;
                color: $TEXT_WHITE;
                position: absolute;
                bottom: 20px;
                left: 16px;
                margin: 0;
                background: #20202099;
                border-radius: 10px;
                padding: 0 5px;
            }
        }
    }

    .recent_highlights {
        padding-top: 24px;

        .tabs_section {

            p.text {
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 700;
                font-size: $FONT_24;
                line-height: 24px;
                align-items: center;
                letter-spacing: 0.25px;
                color: $TEXT_WHITE;
                margin: 0;
            }
        }

        .Tabs {
            padding-top: 24px;

            ul.tab-btn {
                display: flex;
                padding: 0;
                list-style: none;
                gap: 24px;
                margin: 0;

                li.tab_btn_style {
                    background: rgba(255, 255, 255, 0.5);
                    border: 1px solid $BG_WHITE;
                    box-shadow: 2px 2px 0px rgb(55 65 81 / 25%);
                    border-radius: 8px;
                    padding: 4px 10px;
                    display: flex;
                    align-items: center;
                    font-size: $FONT_21;
                    cursor: pointer;

                    span {
                        font-family: $HELVETICA;
                        font-style: normal;
                        font-weight: 400;
                        font-size: $FONT_14;
                        line-height: 16px;
                        color: rgba(1, 14, 25, 0.5);
                        padding-left: 10px;
                        cursor: pointer;
                    }
                }

                li.tab_btn_style.active {
                    background: $BG_YELLLOW_LIGHT !important;
                    color: $TEXT_WHITE;

                    span {
                        color: $TEXT_WHITE;
                    }
                }
            }

            .outlet {
                // padding-top: 24px;
                padding-bottom: 91px;
                margin-top: 24px;
                margin-bottom: 24px;
                overflow-y: auto;

                /* width */
                &::-webkit-scrollbar {
                    width: 6px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #66000000;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: $SCROLLBAR_BG;
                    border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #c0c0c0;
                }

                .outlet_main {
                    display: flex;
                    flex-wrap: wrap;

                    .recent_books_cards {
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
                        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
                        border-radius: 8px;
                        padding: 10px;
                        width: 24%;
                        float: left;
                        margin-right: 1%;
                        margin-bottom: 1%;
                        cursor: pointer;

                        .book_card {
                            display: flex;

                            .left_side {

                                img {
                                    width: 100px;
                                    height: 140px;
                                }
                            }

                            .right_side {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;

                                .contain {
                                    margin-left: 10px;

                                    p.date {
                                        margin: 0;
                                        font-family: $HELVETICA;
                                        font-weight: 400;
                                        font-size: $FONT_12;
                                        line-height: 14px;
                                        color: $TEXT_BLUE;
                                    }

                                    h3.book_name {
                                        margin: 10px 0 0 0;
                                        font-family: $HELVETICA;
                                        font-weight: 700;
                                        font-size: $FONT_18;
                                        line-height: 18px;
                                        letter-spacing: 0.15px;
                                        color: $TEXT_BLUE;
                                    }

                                    h4.book_author {
                                        margin: 10px 0 0 0;
                                        font-family: $HELVETICA;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: $FONT_12;
                                        line-height: 14px;
                                        color: $TEXT_BLUE;
                                    }

                                    h4.novel_name {
                                        margin: 10px 0 0 0;
                                        font-family: $HELVETICA;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: $FONT_14;
                                        line-height: 16px;
                                        letter-spacing: 0.25px;
                                        color: $TEXT_BLUE;
                                    }

                                    h3.region_name {
                                        margin: 10px 0 0 0;
                                        font-family: $HELVETICA;
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: $FONT_12;
                                        line-height: 14px;
                                        letter-spacing: 0.08em;
                                        text-transform: uppercase;
                                        color: $TEXT_DARK_BLUE_DARK;
                                    }
                                }

                                .bookmark_events {

                                    span.icon-fav {
                                        font-size: $FONT_24;
                                        color: $TEXT_WHITE;
                                        cursor: pointer;
                                    }

                                    span.icon-heart-fill {
                                        font-size: $FONT_24;
                                        color: $TEXT_WHITE;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }


                    .Recent_events_card {
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
                        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
                        border-radius: 8px;
                        width: 24%;
                        float: left;
                        margin-right: 1%;
                        margin-bottom: 1%;
                        padding: 10px;
                        cursor: pointer;


                        .top_side {

                            img {
                                height: 180px;
                                width: 100%;
                                object-fit: cover;
                                object-position: top;
                            }
                        }

                        .down_side {

                            .name_bookmark {
                                display: flex;
                                justify-content: space-between;
                                margin-top: 10px;
                                padding-bottom: 14px;

                                .event_name {

                                    h4 {
                                        font-family: $HELVETICA;
                                        font-weight: 700;
                                        font-size: $FONT_18;
                                        line-height: 18px;
                                        letter-spacing: 0.15px;
                                        color: $TEXT_BLUE;
                                        margin: 0;
                                    }
                                }

                                .bookmark_events {

                                    span.icon-fav {
                                        font-size: $FONT_24;
                                        color: $TEXT_WHITE;
                                        cursor: pointer;
                                    }

                                    span.icon-heart-fill {
                                        font-size: $FONT_24;
                                        color: $TEXT_WHITE;
                                        cursor: pointer;
                                    }
                                }
                            }

                            .region_month {
                                display: flex;

                                .region_name {
                                    width: 70%;

                                    p {
                                        font-family: $HELVETICA;
                                        font-style: normal;
                                        font-weight: 700;
                                        font-size: $FONT_12;
                                        line-height: 14px;
                                        letter-spacing: 0.08em;
                                        text-transform: uppercase;
                                        color: $TEXT_DARK_BLUE_DARK;
                                        margin: 0;
                                    }
                                }

                                .month_name {
                                    width: 30%;
                                    display: flex;
                                    justify-content: end;

                                    p {
                                        font-family: $HELVETICA;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: $FONT_12;
                                        line-height: 14px;
                                        display: flex;
                                        align-items: center;
                                        color: $TEXT_BLUE;
                                        margin: 0;
                                    }
                                }
                            }
                        }

                        .bookmark_events {

                            span.icon-fav {
                                font-size: $FONT_24;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }

                            span.icon-heart-fill {
                                font-size: $FONT_24;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }
                        }
                    }

                    .shop_cards {
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
                        box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
                        border-radius: 8px;
                        padding: 10px;
                        width: 24%;
                        float: left;
                        margin-right: 1%;
                        margin-bottom: 1%;

                        .contain_area {
                            display: flex;
                            position: relative;
                            justify-content: space-between;

                            .main_contain {
                                display: flex;

                                .shop_img {

                                    img {
                                        width: 120px;
                                        height: 140px;
                                    }
                                }

                                .shop_text {
                                    margin-left: 10px;

                                    .time {

                                        p {
                                            font-family: $HELVETICA;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: $FONT_12;
                                            line-height: 14px;
                                            color: $TEXT_BLUE;
                                            margin: 0;
                                        }
                                    }

                                    .shop_name {

                                        p {
                                            font-family: $HELVETICA;
                                            font-style: normal;
                                            font-weight: 700;
                                            font-size: $FONT_18;
                                            line-height: 18px;
                                            letter-spacing: 0.15px;
                                            color: $TEXT_BLUE;
                                            margin: 0;
                                            padding-top: 10px;
                                        }
                                    }

                                    .region_name {

                                        p {
                                            font-family: $HELVETICA;
                                            font-style: normal;
                                            font-weight: 700;
                                            font-size: $FONT_12;
                                            line-height: 14px;
                                            display: flex;
                                            align-items: center;
                                            letter-spacing: 0.08em;
                                            text-transform: uppercase;
                                            margin: 0;
                                            padding-top: 10px;
                                        }
                                    }
                                }
                            }

                            .bookmark_events {

                                span.icon-fav {
                                    font-size: $FONT_24;
                                    color: $TEXT_WHITE;
                                    cursor: pointer;
                                }

                                span.icon-heart-fill {
                                    font-size: $FONT_24;
                                    color: $TEXT_WHITE;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.directory_main.dnone {
    display: none !important;
}


.data_main {
    background-image: url(https://res.cloudinary.com/zudu/image/upload/v1657710591/Birlinn/App/Project-images/MainBg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    padding: 0 2.5%;
    width: 100%;

    .title_area {
        display: flex;
        color: #ffff;
        align-items: center;
        padding: 24px 0 0 0;

        a.close {
            color: $TEXT_WHITE;
            text-decoration: none;
            font-size: $FONT_32;
            display: flex;
        }

        p.title_text {
            margin: 0;
            padding: 0;
            font-family: 'Lobster';
            font-style: normal;
            font-weight: 400;
            font-size: 57px;
            line-height: 64px;
            letter-spacing: -0.25px;
            color: $TEXT_WHITE;
            padding-left: 16px;
        }
    }

    .data_n_filter {

        .search_filter {
            display: flex;
            padding-top: 24px;

            .searchBoxDiv {
                width: 600px;
                max-width: 100%;

                .search-icon {
                    left: 0;
                }

                input.searchInput {
                    padding: 6px 45px 6px 45px;
                }
            }

            a.filter_btn {
                display: flex;
                font-size: $FONT_24;
                color: $TEXT_BLUE;
                background: $BG_WHITE;
                box-shadow: 2px 2px 0px rgb(55 65 81 / 25%);
                border-radius: 8px;
                height: 42px;
                margin-left: 24px;
                text-decoration: none;
                align-items: center;
                padding: 8px;
                gap: 10px;
                width: 85px;
                justify-content: center;
                cursor: pointer;

                span {

                    i.icon.icon-filtter {
                        display: flex;
                    }
                }

                span.text_filter_btn {
                    font-family: $HELVETICA;
                    font-style: normal;
                    font-weight: 700;
                    font-size: $FONT_14;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: $TEXT_BLUE;
                }
            }
        }

        .seleted_filter_data {
            display: flex;
            align-items: center;

            p.title {
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 700;
                font-size: $FONT_18;
                line-height: 18px;
                letter-spacing: 0.15px;
                color: $TEXT_WHITE;
                margin: 0;
                min-width: 75px;
            }

            .seleted_item {

                ul.sleteted_ul {
                    display: flex;
                    margin: 0;
                    padding: 0;
                    flex-wrap: wrap;

                    li {
                        background: $BG_WHITE;
                        box-shadow: 2px 2px 0px rgb(55 65 81 / 25%);
                        padding: 8px;
                        list-style: none;
                        margin: 4px 8px;

                        span {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .data_section {
            overflow-y: auto;
            margin-top: 28px;

            /* width */
            &::-webkit-scrollbar {
                width: 6px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #66000000;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: $SCROLLBAR_BG;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #c0c0c0;
            }

            .data_section_show {
                display: flex;
                flex-wrap: wrap;

            }

            .recent_books_cards {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
                box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                padding: 10px;
                width: 24%;
                float: left;
                margin-right: 1%;
                margin-bottom: 1%;
                cursor: pointer;

                .book_card {
                    display: flex;

                    .left_side {

                        img {
                            width: 100px;
                            height: 140px;
                        }
                    }

                    .right_side {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        .contain {
                            margin-left: 10px;

                            p.date {
                                margin: 0;
                                font-family: $HELVETICA;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                color: $TEXT_BLUE;
                            }

                            h3.book_name {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-weight: 700;
                                font-size: $FONT_18;
                                line-height: 18px;
                                letter-spacing: 0.15px;
                                color: $TEXT_BLUE;
                            }

                            h4.book_author {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                color: $TEXT_BLUE;
                            }

                            h4.novel_name {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_14;
                                line-height: 16px;
                                letter-spacing: 0.25px;
                                color: $TEXT_BLUE;
                            }

                            h3.region_name {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_12;
                                line-height: 14px;
                                letter-spacing: 0.08em;
                                text-transform: uppercase;
                                color: $TEXT_DARK_BLUE_DARK;
                            }
                        }

                        .bookmark_events {

                            span.icon-fav {
                                font-size: $FONT_24;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }

                            span.icon-heart-fill {
                                font-size: $FONT_24;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }


            .Recent_events_card {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
                box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                width: 24%;
                margin: 0 1% 1% 0;
                padding: 10px;
                cursor: pointer;

                .top_side {

                    img {
                        width: 100%;
                        height: 180px;
                        object-fit: cover;
                        object-position: top;
                    }
                }

                .down_side {

                    .name_bookmark {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 10px;
                        padding-bottom: 14px;

                        .event_name {

                            h4 {
                                font-family: $HELVETICA;
                                font-weight: 700;
                                font-size: $FONT_18;
                                line-height: 18px;
                                letter-spacing: 0.15px;
                                color: $TEXT_BLUE;
                                margin: 0;
                            }
                        }

                        .bookmark_events {

                            span.icon-fav {
                                font-size: $FONT_24;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }

                            span.icon-heart-fill {
                                font-size: $FONT_24;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }
                        }
                    }

                    .region_month {
                        display: flex;
                        justify-content: space-between;

                        .region_name {

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_12;
                                line-height: 14px;
                                letter-spacing: 0.08em;
                                text-transform: uppercase;
                                color: $TEXT_DARK_BLUE_DARK;
                                margin: 0;
                            }
                        }

                        .month_name {
                            display: flex;
                            justify-content: end;

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                display: flex;
                                align-items: center;
                                color: $TEXT_BLUE;
                                margin: 0;
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }

            .bookmark_events {

                a {
                    text-decoration: none;
                    position: absolute;
                    right: 0;
                    font-size: $FONT_21;
                    color: $TEXT_WHITE;
                    top: 3px;
                }
            }
        }

        .shop_cards {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
            box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            padding: 10px;
            width: 24%;
            float: left;
            margin-right: 1%;
            margin-bottom: 1%;
            cursor: pointer;

            .contain_area {
                display: flex;
                position: relative;
                justify-content: space-between;

                .main_contain {
                    display: flex;

                    .shop_img {

                        img {
                            width: 120px;
                            height: 140px;
                        }
                    }

                    .shop_text {
                        margin-left: 10px;

                        .time {

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                color: $TEXT_BLUE;
                                margin: 0;
                            }
                        }

                        .shop_name {

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_18;
                                line-height: 18px;
                                letter-spacing: 0.15px;
                                color: $TEXT_BLUE;
                                margin: 0;
                                padding-top: 10px;
                            }
                        }

                        .region_name {

                            p {
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_12;
                                line-height: 14px;
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.08em;
                                text-transform: uppercase;
                                margin: 0;
                                padding-top: 10px;
                            }
                        }
                    }
                }

                .bookmark_events {

                    span.icon-fav {
                        font-size: $FONT_24;
                        color: $TEXT_WHITE;
                        cursor: pointer;
                    }

                    span.icon-heart-fill {
                        font-size: $FONT_24;
                        color: $TEXT_WHITE;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.filter_popup.modal {
    .modal-backdrop.show {
        opacity: 0;
    }

    .modal-dialog {
        width: 322px;
        height: auto;
        max-width: 98%;
        position: absolute;
        top: 25%;
        left: 52.5%;
        transform: translate(-50%, -50%);

        .modal-content {
            background: $BODY_BG;
            box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
            border-radius: 8px !important;
            padding: 16px;
            overflow: visible !important;

            .modal-header {
                justify-content: center;

                .modal-title.h4 {
                    font-family: $HELVETICA;
                    font-style: normal;
                    font-weight: 700;
                    font-size: $FONT_16;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    color: $TEXT_WHITE;
                }
            }

            .drop_down.modal-body {
                padding: 24px 0;

                .tabDropdown {
                    margin-left: -6px;

                    div.d-inline.mx-2.dropdown::after {
                        display: none;
                    }

                    .dropdown {
                        width: 100%;
                        display: flex !important;
                        align-items: center;
                        justify-content: center;

                        button.drop_text.dropdown-toggle.btn {
                            overflow-y: hidden;
                            width: 86% !important;
                            margin-right: auto;
                            padding: 15px 10px !important;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-style: normal;
                            line-height: 14px;
                            font-size: 14px !important;

                            span.icon-chevron-down.drop_arrow {
                                font-size: 19px;
                                width: 38px !important;
                                height: 44px !important;
                                display: flex;
                                align-items: center;
                                position: fixed;
                                justify-content: center;
                                top: 64px;
                                right: 17px;
                                background: white;
                                color: #000000;
                            }


                            /* width */
                            &::-webkit-scrollbar {
                                height: 6px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #66000000;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: $BTN_DISABLED;
                                border-radius: 10px;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #c0c0c0;
                            }
                        }

                        .dropdown-menu.show.dropdown-menu-end {
                            transform: translate3d(-1px, -1px, 0px) !important;
                            overflow: visible;
                        }

                        .dropdown-menu.showClose.dropdown-menu-end.dropdown-menu.show.dropdown-menu-end {
                            display: none;
                        }


                        .searchBoxDiv {
                            display: flex;
                            border: 1px solid #CBCED3;
                            height: 42px;

                            input.searchInput {
                                padding: 6px 10px 6px 45px !important;
                                min-height: 40px !important;
                            }

                            .search-icon {
                                left: 0;
                            }
                        }

                        .dropdown-menu {
                            color: $TEXT_DISABLE !important;
                            padding: 0px;
                            max-height: 500%;
                            overflow-y: scroll;
                            border-radius: 0 !important;

                            /* width */
                            &::-webkit-scrollbar {
                                width: 4px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: $BG_WHITE;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: $BG_GRAY;
                                border-radius: 10px;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: $BG_GRAY;
                            }

                            .list_style {
                                overflow-y: scroll !important;
                                background: $BG_WHITE;
                                height: 294px;

                                /* width */
                                &::-webkit-scrollbar {
                                    width: 6px;
                                }

                                /* Track */
                                &::-webkit-scrollbar-track {
                                    background: #66000000;
                                }

                                /* Handle */
                                &::-webkit-scrollbar-thumb {
                                    background: $SCROLLBAR_BG;
                                    border-radius: 10px;
                                }

                                /* Handle on hover */
                                &::-webkit-scrollbar-thumb:hover {
                                    background: #c0c0c0;
                                }

                                .checkboxMain {
                                    position: relative;
                                    padding: 10px;
                                    border-bottom: 0.2px solid $TEXT_DISABLE;
                                    background: $BG_WHITE;

                                    label {
                                        flex-direction: row-reverse;
                                        font-family: $HELVETICA;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: $FONT_16;
                                        color: $TEXT_DISABLE;
                                        outline: none;
                                        display: inline-block;
                                        width: 100%;
                                        padding-right: 10px;

                                        .icon-square,
                                        .checkedIcon,
                                        .icon-square-1 {
                                            position: absolute;
                                            right: 0;
                                            font-family: $HELVETICA;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: $FONT_16;
                                            color: $TEXT_DISABLE;
                                        }
                                    }
                                }

                                .checkboxMain:nth-child(even) {
                                    background: rgba(205, 215, 224, 0.5);
                                }

                                /* Track */
                                &::-webkit-scrollbar-track {
                                    background: $BG_WHITE;
                                }

                                /* Handle */
                                &::-webkit-scrollbar-thumb {
                                    background: $BG_GRAY;
                                    border-radius: 10px;
                                }

                                /* Handle on hover */
                                &::-webkit-scrollbar-thumb:hover {
                                    background: $BG_GRAY;
                                }

                                .list_style {
                                    overflow: scroll !important;
                                    background: $BG_WHITE;

                                    .checkboxMain {
                                        position: relative;
                                        padding: 10px;
                                        border-bottom: 0.2px solid $TEXT_DISABLE;
                                        background: $BG_WHITE;

                                        label {
                                            flex-direction: row-reverse;
                                            font-family: $HELVETICA;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: $FONT_16;
                                            color: $TEXT_DISABLE;
                                            outline: none;
                                            display: inline-block;
                                            width: 100%;
                                            padding-right: 10px;

                                            .icon-square,
                                            .checkedIcon,
                                            .icon-square-1 {
                                                position: absolute;
                                                right: 0;
                                                font-family: $HELVETICA;
                                                font-style: normal;
                                                font-weight: 400;
                                                font-size: $FONT_16;
                                                color: $TEXT_DISABLE;
                                            }
                                        }
                                    }

                                    .checkboxMain:nth-child(even) {
                                        background: rgba(205, 215, 224, 0.5);
                                    }
                                }

                            }
                        }
                    }
                }
            }

            .filter_btn.modal-footer {
                justify-content: center;
                padding: 0 !important;
                border: none !important;

                button.custombtn.apply_btn.btn.btn-primary {
                    text-decoration: none;
                    font-family: $HELVETICA;
                    font-style: normal;
                    font-weight: 400;
                    font-size: $FONT_14 !important;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.25px;
                    background: $BG_ORANGE !important;
                    color: $TEXT_WHITE !important;
                    opacity: 0.5 !important;
                    width: 85px;
                    height: 32px;
                    padding: 6px 23px !important;
                }

                button.custombtn.apply_btn.btn.btn-primary:not(:disabled) {
                    box-shadow: none;
                    opacity: 1 !important;
                    padding: 8px 24px !important;
                    background: $BG_ORANGE !important;

                }

                a.cancle_btn {
                    background: $BTN_DISABLED;
                    padding: 8px 19px;
                    text-decoration: none;
                    font-family: $HELVETICA;
                    font-style: normal;
                    font-weight: 400;
                    font-size: $FONT_14;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.25px;
                    color: $TEXT_PLACEHOLDER;
                    cursor: pointer;
                    width: 85px;
                    height: 32px;
                }
            }
        }
    }
}

.fade.confirmModal.remove_bookmark_popup.modal.show {
    z-index: 999999999;
}

.remove_bookmark_popup.modal .modal-dialog .modal-content {
    background: $BODY_BG;
    min-width: 275px !important;
    height: 162px;
    box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
    border-radius: 8px !important;
}

.remove_bookmark_popup.confirmModal .modal-dialog .modal-content .modal-header {
    color: $TEXT_MODAL;
}

.remove_bookmark_popup.confirmModal .modal-dialog .modal-content .modal-body p {
    font-family: $HELVETICA;
    font-style: normal;
    font-weight: 400;
    font-size: $FONT_14;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.25px;
    color: $TEXT_MODAL;
}

.remove_bookmark_popup.confirmModal .modal-dialog .modal-content .modal-footer .custombtn:nth-child(2) {
    margin-left: 4px;
    background-color: $BTN_MODEL_DISABLED;
    color: $TEXT_WHITE;
    order: 1;
}

.remove_bookmark_popup.confirmModal .modal-dialog .modal-content .modal-footer .custombtn {
    order: 2;
    margin-left: 8px;
}

.remove_bookmark_popup.confirmModal .modal-dialog .modal-content .modal-footer .custombtn:nth-child(2):hover {
    background: $BG_ORANGE;
}

.toast .tostcenter.bookmark_popup {
    background: $BODY_BG;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    border-radius: 8px;
    width: 275px;
}


.toast.bookmark_tost {
    position: fixed;
    top: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 9999;
    text-align: center;
    flex-basis: inherit !important;
    margin: 0 auto !important;
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 16px;
    display: none;
    width: inherit !important;

    .tostcenter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 26px 20px 24px 20px;
        text-align: center;
        margin: 0 auto;
        position: relative;
        width: 275px;
        background: $BODY_BG;
        box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
        -webkit-box-shadow: 0px 32px 64px rgba(17 17 17 / 8%);
        -moz-box-shadow: 0px 32px 64px rgba(17 17 17 / 8%);
        -ms-box-shadow: 0px 32px 64px rgba(17 17 17 / 8%);
        -o-box-shadow: 0px 32px 64px rgba(17 17 17 / 8%);
        position: relative;
        overflow: hidden;
        overflow: hidden;
        border-radius: 8px;

        span.tost_icon {
            color: $TEXT_ORANGE;
        }

        p.bookmark_tost_text {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.25px;
            color: $TEXT_MODAL;
            margin: 10px 0 0 0;
        }
    }
}


.data_main.eventsdata_show {
    display: block !important;
}

.data_main.shopsdata_show {
    display: block !important;
}

.data_main.booksdata_show.dnone {
    display: none;
}

.directory_main.dnone.dblock {
    display: block !important;
}


@media only screen and (max-width: 1650px) {
    .data_main .data_n_filter .data_section .shop_cards {
        width: 32%;
    }

    .data_main .data_n_filter .data_section .recent_books_cards {
        width: 32%;
    }

    .data_main .data_n_filter .data_section .Recent_events_card {
        width: 32%;
    }


    .directory_main .recent_highlights .Tabs .outlet .outlet_main .Recent_events_card {
        width: 32%;
    }

    .directory_main .recent_highlights .Tabs .outlet .outlet_main .recent_books_cards {
        width: 32%;
    }

    .directory_main .recent_highlights .Tabs .outlet .outlet_main .Recent_events_card {
        width: 32%;
    }
}

@media only screen and (max-width: 1400px) {
    .data_main .data_n_filter .data_section .shop_cards {
        width: 49%;
    }

    .data_main .data_n_filter .data_section .recent_books_cards {
        width: 49%;
    }

    .data_main .data_n_filter .data_section .Recent_events_card {
        width: 49%;
    }

    .directory_main .recent_highlights .Tabs .outlet .outlet_main .Recent_events_card {
        width: 49%;
    }

    .directory_main .recent_highlights .Tabs .outlet .outlet_main .recent_books_cards {
        width: 49%;
    }

    .directory_main .recent_highlights .Tabs .outlet .outlet_main .Recent_events_card {
        width: 49%;
    }
}

@media only screen and (max-width: 950px) {
    .data_main .data_n_filter .data_section .shop_cards {
        width: 99%;
    }

    .data_main .data_n_filter .data_section .recent_books_cards {
        width: 99%;
    }

    .data_main .data_n_filter .data_section .Recent_events_card {
        width: 99%;
    }

    .directory_main .recent_highlights .Tabs .outlet .outlet_main .Recent_events_card {
        width: 99%;
    }

    .directory_main .recent_highlights .Tabs .outlet .outlet_main .recent_books_cards {
        width: 99%;
    }

    .directory_main .recent_highlights .Tabs .outlet .outlet_main .Recent_events_card {
        width: 99%;
    }
}