@import "../../assets/scss/variable.scss";

.bookMain {
  padding: 0 0 0 180px;
  position: relative;
  width: 100%;
  background-image: url("https://res.cloudinary.com/zudu/image/upload/v1654764712/Birlinn/Web/Project-Images/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: hidden;

  .headingHeight {
    padding: 0 50px;

    .bookMainPage {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 235px;

      .title {
        font-family: $FONT_LOBSTER;
        font-weight: 400;
        font-size: $FONT_57;
        line-height: 64px;
        color: $TEXT_WHITE;
        padding: 20px 0 24px 0 !important;
        margin: 0px;
      }

      .eventBtn {
        padding: 20px 0 24px 0 !important;

        .customAddEvent {
          width: 132px;
          height: 48px;
          margin-right: 10px;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 700;
          font-size: $FONT_16;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.5px;
          color: $TEXT_WHITE;
          background-color: $BG_ORANGE !important;
          box-shadow: 4px 4px 0px $BG_DARK_ORANGE !important;

          &:not(.disabled):focus {
            box-shadow: none !important;
            background: $BG_ORANGE;
            box-shadow: 4px 4px 0px $BG_DARK_ORANGE !important;
          }
        }

        .customImport {
          width: 101px !important;
          height: 48px !important;
          background: $BODY_BG;
          box-shadow: 4px 4px 0px $BG_LIGHT_BLACK;
          font-family: $HELVETICA;
          font-weight: 700;
          font-size: $FONT_16 !important;
          line-height: 18px;
          text-align: center !important;
          letter-spacing: 0.5px;
          color: $TEXT_WHITE;
        }
      }
    }

    .tabDropdown {
      margin-right: 5px;

      .dropdown {
        width: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;

        .dropdown-menu {
          color: $TEXT_DISABLE !important;
          padding: 0px;
          border-radius: 0 !important;
          box-shadow: 4px 4px 0px $BG_BLUE;
          transform: none !important;
          margin-top: 42px;

          .drop_list {
            overflow-y: scroll;
            border-radius: 0 !important;
            height: 204px;

            /* width */
            &::-webkit-scrollbar {
              width: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: $BG_WHITE;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: $BG_GRAY;
              border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: $BG_GRAY;
            }

          }

          .drop_btns {
            display: flex;
            width: 100%;
            justify-content: center;
          }

          .checkboxMain {
            position: relative;
            padding: 10px;
            border-bottom: 0.2px solid $TEXT_DISABLE;

            label {
              flex-direction: row-reverse;
              font-family: $HELVETICA;
              font-style: normal;
              font-weight: 400;
              font-size: $FONT_16;
              color: $TEXT_DISABLE;
              outline: none;
              display: inline-block;
              width: 100%;
              padding-right: 10px;

              .icon-square,
              .checkedIcon,
              .icon-square-1 {
                position: absolute;
                right: 0;
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 400;
                font-size: $FONT_16;
                color: $TEXT_DISABLE;
              }
            }
          }
        }

        .titleApply {
          max-width: 83px !important;
          background-color: $BG_ORANGE !important;
          box-shadow: 4px 4px 0px $BG_DARK_ORANGE !important;
          font-family: $HELVETICA !important;
          font-weight: 700 !important;
          font-size: 16px !important;
          line-height: 18px !important;
          text-align: center I !important;
          letter-spacing: 0.5px !important;
          color: $TEXT_WHITE !important;
          margin: 20px;
        }
      }
    }

    .setSearch {
      display: flex;
      justify-content: space-between;

      .tabButtonSearch {
        margin-top: 0px;

        .searchBoxDiv {
          position: relative;
          width: 750px;

          .searchInput {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 14px;
            padding: 0px 0px 0px 40px;
            border-radius: 0;
          }

          .search-icon {
            position: absolute;
            left: 0;
            top: 0;
          }

          .icon-x {
            position: absolute;
            top: 0;
          }
        }
      }
    }

    .setPara {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      width: 100%;

      .bookLine {
        font-family: $HELVETICA;
        font-weight: 700;
        font-size: $FONT_16;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $TEXT_WHITE;
        margin: 0;
      }

      .tabDropdownSecond {
        max-width: 130px;

        .customDropdown {
          .dropdown {
            position: relative !important;
            color: $TEXT_WHITE;
            border: none;

            .dropdown-menu {
              border-radius: 0;
              right: 0% !important;
              left: auto !important;
            }

            .btn-primary {
              color: $TEXT_WHITE;
              background: none;
              border: none;
              box-shadow: none;
              display: flex;
              align-items: center;
              padding: 0px;

              &::after {
                border: none;
                content: "\e907" !important;
                font-family: "Birlinn" !important;
              }
            }
          }
        }
      }
    }
  }

  .mainPage {
    padding: 0px 40px;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $SCROLLBAR_BG;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #c0c0c0;
    }

    .heading {
      font-family: $HELVETICA;
      font-style: normal;
      font-weight: 700;
      font-size: $FONT_16;
      line-height: 24px;
      color: $TEXT_WHITE;
      margin-bottom: 24px;
    }

    ul {
      width: 100%;
      float: left;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      .mainIndex {
        width: calc(33.333% - 16px);
        float: left;
        display: flex;
        padding: 12px;
        margin: 8px 8px;
        background-color: $BG_SKY;
        position: relative;
        cursor: pointer;

        &.disabled {
          // opacity: 0.6;
          background: $BG_DISABLED;

          &:hover {
            background: $BG_DISABLED;
          }
        }

        &:hover {
          background-color: $BG_WHITE;

          .mainIndexButton {
            display: flex;
          }

          .TitleWithTag {
            span {
              display: none !important;
            }
          }
        }

        .mainImage {
          object-fit: fill;
          width: 100%;
          max-width: 100px;
          height: 100%;
          max-height: 167px;
          display: block;
          margin-bottom: 33px;
        }

        .mainTitle {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          width: 100%;

          .TitleWithTag {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            margin: 0 0 6px 0;

            h5 {
              margin-bottom: 0;
            }

            p {
              margin-bottom: 0;
            }

            .title {
              font-family: $HELVETICA;
              font-weight: 700;
              font-size: $FONT_24;
              line-height: 150%;
              color: $TEXT_BLUE;
              width: 100%;
              max-width: 70%;
              margin-bottom: 6px;
              word-break: break-all;
            }

            span {
              padding: 10px 8px;
              font-family: $HELVETICA;
              font-weight: 700;
              font-size: $FONT_10;
              line-height: 11px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              color: $BG_DARK_BLUE;
              background: $BG_NAVBAR;
              box-shadow: 4px 4px 0px $BG_LIGHT_BLACK;
            }
          }

          .metaTitle {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: $TEXT_BLUE;
            margin-bottom: 6px;
            word-break: break-all;
          }

          .author {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: $TEXT_DARK_YELLOW;
            margin-bottom: 4px;
          }

          .price {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_28;
            line-height: 36px;
            color: $TEXT_BLUE;
            margin-bottom: 4px;
          }

          p.areaSubTitle {
            p {
              text-align: justify;
            }

            &.table-scroll {
              margin: 0;
              word-break: break-word;

            }
          }

          .discription {
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 150%;
            letter-spacing: 0.4px;
            color: $TEXT_BLUE;
            word-break: break-all;
          }

          .more_btn {
            display: flex;
            justify-content: end;
            margin-bottom: 5px;

            span {
              font-family: "Helvetica";
              font-style: normal;
              font-weight: 700;
              font-size: 10px;
              color: #ffffff;
              background-color: #f7a637 !important;
              padding: 3px 6px;
              border-radius: 9px;
              padding-bottom: 4px;
            }
          }
        }

        .mainIndexButton {
          position: absolute;
          right: 12px;
          top: 12px;
          display: none;

          .customEditbtn {
            background-color: $BG_DARK_GREEN;
            box-shadow: none;
            border: none !important;
            margin-left: 8px;
            padding: 0px;

            .icon-trash-2 {
              display: flex;
              justify-content: center;
              text-align: center;
              align-items: center;
              font-size: $FONT_16;
              padding: 8px;
            }
          }

          .customEditbtn1 {
            background-color: $BG_ORANGE;
            box-shadow: none;
            border: none !important;
            margin-left: 8px;
            padding: 0px;

            .icon-edit-2 {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: $FONT_16;
              padding: 8px;
            }
          }

          .disableDropdown {
            .dropdown-toggle {
              height: 32px;
              background-color: $BG_ORANGE;
              box-shadow: none;
              border: none !important;
              margin-left: 8px;
              border-radius: 0px;
              box-shadow: none;
              padding: 0;

              &::after {
                border-width: 0;
                content: "\e921";
                font-family: "Birlinn";
                font-size: $FONT_16;
                padding: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -3px;
              }
            }

            .dropdown-menu {
              min-width: 120px;
              background: $BG_WHITE;
              border-radius: 0px;
              box-shadow: 4px 4px 0px $BG_BLUE;
              padding: 0px;
              right: 0 !important;
              left: auto !important;
              margin: 10px;

              .dropdown-item {
                padding: 9px;
                color: $TEXT_DISABLE;
                z-index: 1000;

                &:active {
                  background: $BG_WHITE;
                }
              }
            }
          }

          .icon-more-vertical {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.bookOffcanvas {
  width: 600px !important;
  background-color: $BODY_BG !important;

  .offcanvas-header {
    padding: 36px;
    padding-bottom: 0px !important;

    .alignCenters {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .offcanvas-body {
    padding: 36px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $BODY_BG;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $BODY_BG;
    }

    .scottishCard {
      width: 100%;
      float: left;
      display: flex;
      margin-bottom: 34px;

      .scottishCardLeft {
        margin-right: 24px;
        width: 100%;
        max-width: 150px;
        min-height: 200px;
        height: 100%;
        object-fit: fill;
      }

      .scottishCardRight {
        p {
          padding: 0;
          margin: 0;
        }

        .title {
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_14;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: $TEXT_WHITE;
          margin-bottom: 8px;
        }

        .metaTitle {
          font-family: $FONT_LOBSTER;
          font-weight: 400;
          line-height: 44px;
          letter-spacing: -1.5px;
          font-size: $FONT_36;
          color: $TEXT_WHITE;
          margin-bottom: 8px;
        }

        .author {
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_14;
          line-height: 20px;
          color: $TEXT_WHITE;
          margin-bottom: 8px;
        }

        .discription {
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_14;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: $TEXT_WHITE;
          margin-bottom: 8px;
        }

        .price {
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_28;
          line-height: 36px;
          color: $TEXT_WHITE;
          margin-bottom: 8px;
        }
      }

      .scottishCardMain {
        width: 100%;
        float: left;
        display: flex;

        .scottishCardMainInner {
          padding: 8px;
          background-color: $BG_WHITE;
          font-family: $HELVETICA;
          font-weight: 700;
          font-size: $FONT_12;
          line-height: 14px;
          color: $TEXT_DARK_SKY;
          margin-left: 11px;
          margin-bottom: 24px;
          text-align: center;
        }

        .scottishCardMainInner:nth-child(1) {
          margin-left: 0;
        }
      }
    }

    .scottishCardButton {
      width: 100%;
      float: left;
      display: flex;

      .scottishButton {
        width: 92px !important;
        height: 42px !important;
        background: $BG_ORANGE;
        box-shadow: 4px 4px 0px $TEXT_DARK_YELLOW;
        color: $TEXT_WHITE;
        border: none;
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 700;
        font-size: $FONT_16;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        padding: 12px 16px;
        margin-left: 14px;
        margin-bottom: 24px;

        .icon-edit-2 {
          margin-right: 11px;
        }

        .icon-headphones {
          color: $TEXT_WHITE;
          margin-right: 11px;
        }

        .icon-minus-circle {
          color: $TEXT_WHITE;
          margin-right: 11px;
        }

        .icon-trash-2 {
          color: $TEXT_WHITE;
          margin-right: 11px;
        }
      }

      .scottishButton:nth-child(1) {
        margin-left: 0;
      }

      .scottishButton:nth-child(2) {
        width: 115px !important;
        height: 42px !important;
      }

      .scottishButton:nth-child(3) {
        height: 42px !important;
        width: 113px !important;
      }
    }

    .bookTitle {
      font-family: $HELVETICA;
      font-weight: 700;
      font-size: $FONT_16;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $TEXT_WHITE;
      margin-bottom: 8px;
    }

    .aboutPart {
      width: 100%;
      float: left;
      display: flex;

      .aboutImage {
        margin-right: 8px;
      }

      .booktext {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_14;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: justify;
        letter-spacing: 0.25px;
        color: $TEXT_WHITE;
        margin-bottom: 24px;
      }
    }

    .booktextContent {
      font-family: $HELVETICA;
      font-weight: 400;
      font-size: $FONT_14;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $TEXT_WHITE;
      margin-bottom: 24px;
      word-break: break-word;

      img {
        max-height: 300px;
      }

      iframe {
        width: 100%;
        height: 250px;
      }
    }

    .aboutAuthorTitle {
      font-family: $HELVETICA;
      font-weight: 700;
      font-size: $FONT_16;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: $TEXT_WHITE;
      margin-bottom: 8px;
    }

    .aboutAuthortext {
      font-family: $HELVETICA;
      font-weight: 400;
      font-size: $FONT_14;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $TEXT_WHITE;
      margin-bottom: 24px;
    }

    .locationTitle {
      font-family: $HELVETICA;
      font-weight: 700;
      font-size: $FONT_16;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $TEXT_WHITE;
    }

    .locationText {
      font-family: $HELVETICA;
      font-weight: 400;
      font-size: $FONT_14;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $TEXT_WHITE;
      margin-bottom: 4px;
    }
  }
}

.AddbookOffcanvas {
  width: 720px !important;
  background-color: $BODY_BG !important;

  .offcanvas-header {
    padding: 36px;
    padding-bottom: 0px !important;

    .alignCenters {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }
    }

    .Addbtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 92%;
      float: left;

      .Addtitle {
        font-family: $FONT_LOBSTER;
        font-weight: 400;
        font-size: $FONT_36;
        line-height: 44px;
        letter-spacing: -1.5px;
        color: $TEXT_WHITE;
        margin-bottom: 15px;
      }

      .customAddbtn {
        font-family: $HELVETICA !important;
        font-weight: 700 !important;
        font-size: $FONT_16;
        line-height: 18px;
        width: 81px;
        height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.5px;

        &:not(.disabled):focus {
          box-shadow: none !important;
          background: $BG_ORANGE;
          box-shadow: 4px 4px 0px $BG_DARK_ORANGE !important;
        }
      }

      .customUpdatebtn {
        max-width: 105px;
        min-height: 40px;
      }
    }
  }

  .offcanvas-body {
    padding: 0 36px 36px 36px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $BODY_BG;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $BODY_BG;
    }

    .addImage {
      display: flex;
      justify-content: space-between;
      width: 100%;
      float: left;
      margin-top: 24px;

      .setImage {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        margin-top: 30px;
        width: 15%;
        align-items: center;
        display: flex;
      }

      .leftBox {
        width: calc(100% - 80px);
        // background: rgba(0, 0, 0, 0.5);
        text-align: center;

        .updateSectionaudio {
          width: 100%;

          .imgBoxEmpty {
            .imageShow {
              width: 112px;
              height: 150px;
              overflow: hidden;
              float: left;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }

              &:hover {
                .importImg {
                  display: flex;
                }
              }

              .importImg {
                display: none;
                position: absolute;
                margin: 0;
                top: 0px;
                left: 0px;
                height: 100%;
                width: 100%;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background: linear-gradient(0deg,
                    $BG_DARK_BLACK,
                    $BG_DARK_BLACK );

                .fileImported {
                  margin-bottom: 0px;
                }

                .icon-upload {
                  color: $TEXT_WHITE;
                  font-size: $FONT_40;
                  margin-bottom: 4px;
                }
              }
            }

            .uploadedImg {
              width: 100%;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: $BG_DARK_BLACK;

              .audioRegion {
                width: 92px;
                height: 120px;
                object-fit: cover;
                position: absolute;
                left: 114px;
                top: 100px;
              }
            }
          }
        }

        .importImg {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;

          .icon-upload-1 {
            color: $TEXT_WHITE;
            margin-right: 16px;
          }

          p {
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 16px;
            color: $TEXT_WHITE;
          }
        }
      }
    }

    .fieldPartsTitle {
      display: flex;
      justify-content: space-between;
      float: left;
      margin-top: 24px;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;

      .setTitle {
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 90px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartsubName {
      display: flex;
      justify-content: space-between;
      float: left;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;

      .setsubName {
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 90px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartsbook {
      display: flex;
      justify-content: space-between;
      float: left;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;

      .setBook {
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartstexteditor {
      display: flex;
      justify-content: space-between;
      float: left;
      width: 100%;

      .settexteditor {
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .updateSectioneditor {
        width: calc(100% - 90px);

        .ql-toolbar.ql-snow {
          background-color: $BG_WHITE;

          .ql-formats {
            margin: 0;

            button.ql-image {
              // display: none !important;
            }

            button {
              margin-right: 16px;
              width: 31px;
            }
          }
        }

        .ql-editor {
          background-color: $BG_WHITE;
          height: 108px;

          img {
            max-height: 250px;
            width: auto;
          }

          iframe {
            height: 250px;
            width: 100%;
          }
        }
      }
    }

    .fieldPartsradio {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      float: left;
      width: 100%;

      .setRadio {
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 24px;

        p {
          margin-bottom: 0;
          align-items: center;
        }
      }

      .radio_row {
        display: flex;
        width: 100%;

        .RadioButton:nth-child(1) {
          margin-right: 16px;
        }

        .RadioButton:nth-child(2) {
          margin-right: 16px;
        }

        .RadioButton:nth-child(3) {
          margin-right: 16px;
        }

        .RadioButton:nth-child(4) {
          margin-right: 16px;
        }
      }
    }

    .fieldPartsPrice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      float: left;
      width: 100%;

      .setPrice {
        font-family: $HELVETICA;
        font-style: normal;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
          font-size: 12px;
        }
      }
    }

    .fieldPartSurl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      float: left;
      width: 100%;

      .setUrlName {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartsGenre {
      display: flex;
      justify-content: space-between;
      float: left;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;

      .setGenre {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 13.8%;
        margin-right: 8px;
      }

      .BooktabDropdown {
        width: calc(100% - 80px);
        margin-bottom: 16px;

        .customDropdown {
          .dropdown {
            width: 100%;
            text-align: left;

            .dropdown-toggle {
              position: inherit;
              left: 0;
              background-color: $BG_WHITE;
              width: 100%;
              height: 40px;
              color: $TEXT_PLACEHOLDER;
              text-align: left;
              border: none;
              position: relative;
              border-radius: 0;
              font-family: $HELVETICA;
              font-weight: 400;
              font-size: $FONT_12;
              line-height: 14px;
              box-shadow: none;
              outline: none;

              &::after {
                content: "\e907";
                font-family: "birlinn" !important;
                border: none;
                font-size: $FONT_20;
                right: 8px;
                top: 14px;
                position: absolute;
              }
            }
          }

          .selected {
            &.dropdown {
              .btn-primary {
                color: $TEXT_DARK_BLUE !important;
              }
            }
          }

          .dropdown-menu {
            width: 100%;
            max-height: 200px;
            border-radius: 0;
            overflow-y: scroll;

            .dropdown-item {
              &:active {
                background-color: transparent !important;
                color: $BG_BLACK;
              }
            }
          }
        }
      }
    }

    .fieldPartsDate {
      display: flex;
      justify-content: space-between;
      float: left;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;

      .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
        display: none;
      }

      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__month-year-read-view--down-arrow {
        display: none;
      }

      .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
        margin-top: 0;
        color: #000;
        font-weight: bold;
        font-size: 0.944rem;

        .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--scroll {

          .react-datepicker__month-dropdown {
            width: 60% !important;
            left: 20% !important;
          }
        }
      }

      .PublishDate {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 15.8%;
        margin-right: 8px;
      }

      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          .custom-date-input {
            width: 100%;
            position: relative;
            height: 40px;
            border: none;
            margin-bottom: 24px;
            background-color: $BG_NAVBAR;
            text-align: left;
            padding: 10px;
            font-size: $FONT_12;


            span {
              color: $TEXT_DARK_BLUE;

              h6 {
                font-size: $FONT_12;
                color: $TEXT_PLACEHOLDER;
                text-align: left;
                padding: 4px;
              }

              .icon-calendar {
                position: absolute;
                right: 15px;
                top: 8px;
                font-size: $FONT_22;
                color: $TEXT_PLACEHOLDER !important;

                // &::before {
                //   color: $TEXT_DARK_BLUE;
                // }
              }
            }


          }

          .react-datepicker__tab-loop {
            .react-datepicker__tab-loop__start {
              .react-datepicker-popper {
                .react-datepicker {
                  .react-datepicker__triangle &::after {
                    left: -28px !important;
                  }
                }
              }
            }
          }
        }
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartsAuthor {
      display: flex;
      justify-content: space-between;
      float: left;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;

      .authorName {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 13.8%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldaboutAuthor {
      display: flex;
      justify-content: space-between;
      float: left;
      width: 100%;
      margin-bottom: 0px;

      .aboutName {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
          height: 148px;
        }
      }
    }

    .fieldPartsRegion {
      display: flex;
      justify-content: space-between;
      float: left;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;

      .setRegion {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 13.8%;
        margin-right: 8px;
      }

      .BooktabRegion {
        width: calc(100% - 80px);
        margin-bottom: 16px;

        .customDropdown {
          .dropdown {
            width: 100%;
            text-align: left;

            .dropdown-toggle {
              background-color: $BG_WHITE;
              width: 100%;
              height: 40px;
              color: $TEXT_PLACEHOLDER;
              text-align: left;
              border: none;
              position: relative;
              border-radius: 0;
              font-family: $HELVETICA;
              font-weight: 400;
              font-size: $FONT_12;
              line-height: 14px;
              box-shadow: none;
              outline: none;

              &::after {
                content: "\e907";
                font-family: "birlinn" !important;
                border: none;
                font-size: $FONT_20;
                color: $BG_BLACK;
                right: 8px;
                top: 14px;
                position: absolute;
              }
            }
          }

          .selected {
            &.dropdown {
              .btn-primary {
                color: $TEXT_DARK_BLUE !important;
              }
            }
          }

          .dropdown-menu {
            width: 100%;
            border-radius: 0;
            max-height: 400px;
            overflow-y: scroll;

            .dropdown-item {
              &:active {
                background-color: transparent !important;
                color: $BG_BLACK;
              }
            }
          }
        }
      }
    }

    .location_part {
      width: 100%;
      float: left;
      display: flex;

      .setRegion {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 15.5%;
        margin-right: 8px;
        display: flex;
        align-items: center;
      }

      .locationRightPart {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
}

.bookConfirmModal {
  &.confirmModal {
    .modal-dialog {
      display: block;

      .modal-content {
        .modal-footer {
          .custombtn:nth-child(1) {
            max-width: 69px;
            min-height: 30px;
          }

          .custombtn:nth-child(2) {
            color: $TEXT_DARK_BLUE;
          }
        }
      }
    }
  }
}

.bookImportModal {
  .modal-dialog {
    width: 460px;

    .modal-content {
      padding: 16px 16px;
      position: absolute;
      top: 0;

      .updateSection {
        width: 100%;
        height: 150px;
        overflow: hidden;
        text-align: center;
        margin-bottom: 12px;
        position: relative;
        background-color: $BODY_BG;
        color: $TEXT_WHITE;

        input {
          display: none;
        }

        .importFileIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .icon {
            color: $BG_WHITE;
            margin-right: 8px;
            font-size: $FONT_18;
          }

          .csfileUploader {
            color: $TEXT_WHITE;
            font-size: $FONT_12;
            line-height: 14px;
            font-family: $HELVETICA;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .fileName {
              max-width: 65%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0;
            }

            .fileSize {
              padding-left: 8px;
            }
          }
        }

        &:hover {
          .importImg {
            display: flex;
          }
        }

        .importImg {
          display: none;
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;

          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .btnInline {
    display: flex;
    justify-content: center;

    .custombtn {
      margin-right: 10px;
      box-shadow: none;
      width: 69px;
      height: 30px;
      display: flex;
      justify-content: center;
      padding: 6px 12px;
      font-family: $HELVETICA;
      font-weight: 400 !important;
      font-size: $FONT_12 !important;
      line-height: 16px;
      color: $TEXT_WHITE;
      letter-spacing: 0.4px;
      background-color: $BG_ORANGE;

      &:disabled {
        cursor: no-drop;
        background: $BG_ORANGE !important;
        opacity: 0.5 !important;
        pointer-events: all;
        color: $TEXT_WHITE !important;
        border: 1px solid transparent !important;
      }
    }

    .custombtn:nth-child(2) {
      width: 64px;
      height: 30px;
      color: $TEXT_DARK_BLUE;
      background-color: $BTN_MODEL_DISABLED;
    }
  }
}

.leftHeader {
  .nav {
    margin-right: 55px;
  }
}

.fade.offcanvas-backdrop.show {
  height: 100%;
}

@media only screen and (max-width: 1600px) {
  .mainPage {
    ul {
      .mainIndex {
        width: calc(49.5% - 11px) !important;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .mainPage {
    ul {
      .mainIndex {
        width: calc(49.5% - 11px) !important;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .bookMain {
    .bookMainPage {

      // padding-right: 0px !important;
      .eventBtn {
        margin-top: 67px;
        margin-right: -188px;
      }
    }

    .setSearch {
      .tabButtonSearch {
        .searchBoxDiv {
          max-width: 500px !important;
        }
      }
    }
  }

  .mainPage {
    ul {
      .mainIndex {
        width: calc(100% - 15px) !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .bookMain {
    .bookMainPage {

      // padding-right: 0px !important;
      .eventBtn {
        margin-top: 67px;
        margin-right: -188px;
      }
    }

    .setSearch {
      .tabButtonSearch {
        .searchBoxDiv {
          max-width: 500px !important;
        }
      }
    }
  }

  .mainPage {
    ul {
      .mainIndex {
        width: calc(100% - 15px) !important;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .bookMain {
    .bookMainPage {
      padding-right: 0px !important;
    }

    .setSearch {
      .tabButtonSearch {
        .searchBoxDiv {
          max-width: 350px !important;
        }
      }
    }
  }

  .headingHeight {
    .bookMainPage {
      .title {
        font-size: 42px !important;
      }

      .eventBtn {
        margin-top: 67px;
        margin-right: 16px;
      }
    }

    .tabDropdown {
      .dropdown {
        .dropdown-menu {
          .titleApply {
            margin: 5px 0px 10px 83px !important;
          }
        }
      }
    }
  }

  .leftHeader {
    .nav {
      margin-right: 34px;
    }
  }

  .mainPage {
    padding: 0 13px 0 15px !important;

    ul {
      .mainIndex {
        width: calc(100.33% - 15px) !important;
      }
    }
  }
}

@media only screen and (max-width: 810px) {
  .bookMain {
    padding: 0 0 0 115px;
  }

  .headingHeight .bookMainPage .title {
    font-size: 38px !important;
  }
}