@import "../../../../assets/scss/variable.scss";

.confirmModal {
  .modal-dialog {
    width: 100%;
    max-width: 250px;

    .modal-content {
      padding: 0px 16px 0px 16px;
      border: 0;
      border-radius: 16px;
      overflow: hidden;
      width: 100%;
      max-width: 250px;
      height: 136px;
      box-shadow: 16px -16px 65px rgba(0, 0, 0, 0.02);
      -webkit-box-shadow: 16px -16px 65px rgba(0, 0, 0, 0.02);
      -moz-box-shadow: 16px -16px 65px rgba(0, 0, 0, 0.02);
      -ms-box-shadow: 16px -16px 65px rgba(0, 0, 0, 0.02);
      -o-box-shadow: 16px -16px 65px rgba(0, 0, 0, 0.02);

      .modal-header {
        padding: 16px 16px 0px 16px;
        border-radius: 0;
        border: 0;
        text-align: center;
        position: relative;
        display: block;
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 700;
        font-size: $FONT_16;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.5px;
        color: $TEXT_BLUE;
      }

      .modal-body {
        padding: 0;
        margin-bottom: 12px;

        p {
          text-align: center;
          margin: 0;
          font-family: $HELVETICA;
          font-size: $FONT_12;
          font-weight: 400;
          line-height: 14px;
          color: $TEXT_BLUE;
        }
      }

      .modal-footer {
        padding: 0;
        margin-bottom: 16px;
        border: 0;
        border-radius: 0;
        display: flex;
        justify-content: center;

        .custombtn {
          max-width: 70px;
          height: 30px;
          display: flex;
          box-shadow: none !important;
          align-items: center;
          justify-content: center;
          font-family: $HELVETICA;
          font-style: normal;
          font-weight: 400 !important;
          font-size: 12px !important;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: $TEXT_WHITE;

          &:focus-visible {
            outline: none;
          }
        }

        .custombtn:nth-child(2) {
          margin-left: 10px;
          background-color: $BTN_MODEL_DISABLED;
          color: $BG_DARK_BLUE;
        }
      }
    }
  }
}
