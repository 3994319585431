@import "../../assets/scss/variable.scss";

.shopMain {
  padding: 0 0 0 180px;
  position: relative;
  width: 100%;
  background-image: url("https://res.cloudinary.com/zudu/image/upload/v1654764712/Birlinn/Web/Project-Images/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: hidden;

  .headingHeight {
    padding: 0 50px;

    .setShop {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-right: 235px;

      .title {
        font-family: $FONT_LOBSTER;
        font-weight: 400;
        font-size: $FONT_57;
        letter-spacing: -0.25px;
        color: $TEXT_WHITE;
        padding: 20px 0 24px 0 !important;
        margin: 0px;
      }

      .eventBtn {
        padding: 20px 0 24px 0 !important;

        .customAddEvent {
          width: 132px;
          height: 48px;
          margin-right: 10px;
          font-family: $HELVETICA;
          font-weight: 700;
          font-size: $FONT_16;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.5px;
          color: $TEXT_WHITE;

          &:not(.disabled):focus {
            box-shadow: none !important;
            background: $BG_ORANGE;
            box-shadow: 4px 4px 0px $BG_DARK_ORANGE !important;
          }
        }

        .customImport {
          width: 101px !important;
          height: 48px !important;
          background: $BODY_BG;
          box-shadow: 4px 4px 0px $BG_LIGHT_BLACK;
          font-family: $HELVETICA;
          font-weight: 700;
          font-size: $FONT_16 !important;
          line-height: 18px;
          text-align: center !important;
          letter-spacing: 0.5px;
          color: $TEXT_WHITE;
        }
      }
    }

    .tabDropdown {
      margin-right: 5px;

      .dropdown {
        width: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;

        .dropdown-menu {
          color: $TEXT_DISABLE !important;
          padding: 0px;
          border-radius: 0 !important;
          box-shadow: 4px 4px 0px $BG_BLUE;
          transform: none !important;
          margin-top: 42px;

          .drop_list {
            overflow-y: scroll;
            border-radius: 0 !important;
            height: 204px;

            /* width */
            &::-webkit-scrollbar {
              width: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: $BG_WHITE;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: $BG_GRAY;
              border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: $BG_GRAY;
            }

          }

          .drop_btns {
            display: flex;
            width: 100%;
            justify-content: center;
          }

          .checkboxMain {
            position: relative;
            padding: 10px;
            border-bottom: 0.2px solid $TEXT_DISABLE;

            label {
              flex-direction: row-reverse;
              font-family: $HELVETICA;
              font-weight: 400;
              font-size: $FONT_16;
              color: $TEXT_DISABLE;
              outline: none;
              display: inline-block;
              width: 100%;
              padding-right: 10px;

              .icon-square,
              .checkedIcon,
              .icon-square-1 {
                position: absolute;
                right: 0;
                font-family: $HELVETICA;
                font-weight: 400;
                font-size: $FONT_16;
                color: $TEXT_DISABLE;
              }
            }
          }

          .titleApply {
            max-width: 83px !important;
            background-color: $BG_ORANGE !important;
            box-shadow: 4px 4px 0px $BG_DARK_ORANGE !important;
            font-family: $HELVETICA !important;
            font-weight: 700 !important;
            font-size: 16px !important;
            line-height: 18px !important;
            text-align: center I !important;
            letter-spacing: 0.5px !important;
            color: $TEXT_WHITE !important;
            margin: 5px 0px 10px 15px;
          }
        }
      }
    }

    .setSearch {
      display: flex;
      justify-content: space-between;

      .tabButtonSearch {
        margin-top: 0px;

        .searchBoxDiv {
          position: relative;
          width: 750px;

          .searchInput {
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 14px;
            border-radius: 0;
          }

          .search-icon {
            position: absolute;
            left: 0;
            top: 0;
          }

          .icon-x {
            position: absolute;
            top: 0;
          }
        }
      }
    }

    .setPara {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
      width: 100%;

      .bookLine {
        font-family: $HELVETICA;
        font-weight: 700;
        font-size: $FONT_16;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $TEXT_WHITE;
        margin: 0;
      }

      .tabDropdownSecond {
        max-width: 130px;

        .customDropdown {
          .dropdown {
            position: relative !important;
            color: $TEXT_WHITE;
            border: none;

            .btn-primary {
              color: $TEXT_WHITE;
              background: none;
              border: none;
              box-shadow: none;
              display: flex;
              align-items: center;
              padding: 0px;

              &::after {
                border-width: 0;
                content: "\e907";
                font-family: "Birlinn";
              }
            }
          }
        }
      }
    }
  }

  .shopPage {
    width: 100%;
    overflow-y: auto;
    padding: 0 24px 0 46px;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $SCROLLBAR_BG;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $SCROLLBAR_BG;
    }

    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;

      .shopIndex {
        width: calc(20% - 16px);
        padding: 8px 12px;
        text-align: center;
        margin-right: 16px;
        margin-bottom: 24px;
        background-color: $BG_SKY;
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &.disabled {
          background: $BG_DISABLED;

          &:hover {
            background: $BG_DISABLED;
          }
        }

        &:hover {
          background-color: $BG_WHITE;

          .shopIndexButton {
            display: flex;
          }
        }

        .tagBtn {
          position: absolute;
          width: 87px;
          height: 32px;
          bottom: 70px;
          right: 12px;
          background: $BG_NAVBAR;
          box-shadow: 4px 4px 0px $BG_LIGHT_BLACK;

          p {
            font-family: $HELVETICA;
            font-weight: 700;
            font-size: $FONT_10;
            line-height: 11px;
            display: flex;
            align-items: center;
            color: $TEXT_DARK_BLUE;
            margin: 10.5px 8px;
          }
        }

        .shopImage {
          width: 100%;
          height: 150px;
          object-fit: fill;
        }

        .shopCard {
          margin-top: 16px;

          .TitleWithTag {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 0 0 6px 0;

            .title {
              margin: 0;
              font-family: $HELVETICA;
              font-weight: 700;
              font-size: $FONT_24;
              line-height: 150%;
              color: $TEXT_BLUE;
              text-align: left;
              word-break: break-word;
            }

            span {
              padding: 10px 8px;
              font-family: $HELVETICA;
              font-weight: 700;
              font-size: 10px;
              line-height: 11px;
              margin: 5px;
              color: $BG_DARK_BLUE;
              background: $BG_NAVBAR;
              box-shadow: 4px 4px 0px $BG_LIGHT_BLACK;
            }
          }

          .metaTitle {
            margin: 0;
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: $FONT_16;
            line-height: 150%;
            color: $TEXT_BLUE;
            text-align: left;
          }
        }
      }

      .shopIndexButton {
        position: absolute;
        right: 10px;
        top: 12px;
        display: none;

        .customEditbtn {
          padding: 0;
          background-color: $BG_DARK_GREEN;
          box-shadow: none;
          border: none !important;
          margin-left: 8px;

          .icon-trash-2 {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            padding: 8px;
            font-size: $FONT_16;
          }
        }

        .customEditbtn1 {
          background-color: $BG_ORANGE;
          box-shadow: none;
          border: none !important;
          margin-left: 8px;
          padding: 8px;

          .icon-edit-2 {
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
          }
        }

        .disableDropdown {
          .dropdown-toggle {
            height: 32px;
            background-color: $BG_ORANGE;
            box-shadow: none;
            border: none !important;
            margin-left: 8px;
            border-radius: 0px;
            box-shadow: none;
            padding: 0;

            &::after {
              border-width: 0;
              content: "\e921";
              font-family: "Birlinn";
              font-size: $FONT_16;
              padding: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: -3px;
            }
          }

          .dropdown-menu {
            min-width: 120px;
            background: $BG_WHITE;
            border-radius: 0px;
            box-shadow: 4px 4px 0px $BG_BLUE;
            padding: 0px;
            right: 0 !important;
            left: auto !important;
            margin: 10px;

            .dropdown-item {
              padding: 9px;
              color: $TEXT_DISABLE;
              z-index: 1000;

              &:active {
                background-color: transparent !important;
                color: $BG_BLACK;
              }
            }
          }
        }

        .icon-more-vertical {
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
        }
      }
    }
  }
}

.shopOffcanvas {
  width: 600px !important;
  background: $BODY_BG !important;

  .offcanvas-header {
    padding: 36px;
    padding-bottom: 0px !important;

    .alignCenters {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .offcanvas-body {
    padding: 0px 36px 36px 36px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $BODY_BG;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $BODY_BG;
    }

    .mainshopCard {
      width: 100%;
      float: left;
      display: flex;

      .scottishCardLeft {
        margin-right: 24px;
        width: 150px;
        height: 200px;
      }

      .scottishCardRight {
        p {
          padding: 0;
          margin: 0;
        }

        .metaTitleshop {
          font-family: $FONT_LOBSTER;
          font-weight: 400;
          line-height: 44px;
          letter-spacing: -1.5px;
          font-size: $FONT_36;
          color: $TEXT_WHITE;
          margin-bottom: 8px;
        }

        .authorshop {
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_14;
          line-height: 20px;
          color: $TEXT_WHITE;
          margin-bottom: 8px;
        }
      }

      .detailsCardMain {
        width: 100%;
        float: left;
        display: flex;

        .detailsCardMainInner {
          width: 69px;
          height: 36px;
          padding: 10px;
          background-color: $BG_WHITE;
          font-family: $HELVETICA;
          font-weight: 700;
          font-size: $FONT_12;
          line-height: 14px;
          color: $TEXT_DARK_SKY;
          margin-right: 12px;
          margin-bottom: 24px;
          text-align: center;
          box-shadow: 4px 4px 0px $BG_LIGHT_BLACK;
        }
      }
    }

    .detailsCardButton {
      width: 100%;
      float: left;
      display: flex;
      margin-top: 24px;

      .detailsButton {
        width: 120px;
        height: 42px;
        background: $BG_ORANGE;
        box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
        color: $TEXT_WHITE;
        border: none;
        font-family: $HELVETICA;
        font-weight: 700;
        font-size: $FONT_16;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        padding: 12px 16px;
        margin-left: 14px;
        margin-bottom: 24px;

        .icon-edit-2 {
          margin-right: 12px !important;
        }

        .icon-headphones {
          color: $TEXT_WHITE;
          margin-right: 11px;
        }

        .icon-minus-circle {
          color: $TEXT_WHITE;
          margin-right: 11px;
        }

        .icon-trash-2 {
          color: $TEXT_WHITE;
          margin-right: 11px;
        }
      }

      .detailsButton:nth-child(1) {
        width: 92px;
        margin-left: 0;
      }

      .detailsButton:nth-child(3) {
        width: 113px !important;
      }
    }

    .shopTitle {
      font-family: $HELVETICA;
      font-weight: 700;
      font-size: $FONT_16;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $TEXT_WHITE;
      margin-bottom: 8px;
    }

    .aboutPartshop {
      width: 100%;
      float: left;
      display: flex;

      .aboutshopImage {
        margin-right: 8px;
      }

      .aboutshoptext {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_14;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: $TEXT_WHITE;
        margin-bottom: 8px;

        img {
          max-height: 300px;
        }

        iframe {
          width: 100%;
          height: 250px;
        }
      }
    }

    .shoptextContent {
      float: left;
      font-family: $HELVETICA;
      font-style: normal;
      font-weight: 400;
      font-size: $FONT_14;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: justify;
      letter-spacing: 0.25px;
      color: $TEXT_WHITE;
      margin-bottom: 24px;
      margin-top: 8px;
    }

    .serviceTimings {
      margin: 0 !important;

      p {
        font-family: $HELVETICA;
        font-weight: 700;
        font-size: $FONT_16;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $TEXT_WHITE;
        margin-bottom: 0 px !important;
      }
    }

    .Timingsweekend {
      .days {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_14;
        letter-spacing: 0.1px;
        color: $TEXT_WHITE;
        margin-bottom: 24px;
      }
    }

    .shopLocation {
      font-family: $HELVETICA;
      font-weight: 700;
      font-size: $FONT_16;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $TEXT_WHITE;
    }

    .locationTitle {
      font-family: $HELVETICA;
      font-weight: 400;
      font-size: $FONT_14;
      line-height: 20px;
      color: $TEXT_WHITE;
    }
  }
}

.AddShopOffcanvas {
  width: 720px !important;
  background-color: $TEXT_BLUE !important;

  .offcanvas-header {
    padding: 36px;
    padding-bottom: 0px !important;

    .alignCenters {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }
    }

    .addbtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 92%;
      margin-bottom: 16px;

      .Addtitle {
        font-family: $FONT_LOBSTER;
        font-weight: 400;
        font-size: $FONT_36;
        line-height: 44px;
        letter-spacing: -1.5px;
        color: $TEXT_WHITE;
      }

      .customAddbtn {
        font-family: $HELVETICA !important;
        font-weight: 700 !important;
        font-size: $FONT_16;
        line-height: 18px;
        max-width: 81px;
        min-height: 40px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.5px;

        &:not(.disabled):focus {
          box-shadow: none !important;
          background: $BG_ORANGE;
          box-shadow: 4px 4px 0px $BG_DARK_ORANGE !important;
        }
      }

      .customUpdatebtn {
        max-width: 105px;
        min-height: 40px;
      }
    }
  }

  .offcanvas-body {
    padding: 0px 36px 36px 36px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $BODY_BG;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $BODY_BG;
    }

    .addImage {
      display: flex;
      justify-content: space-between;
      width: 100%;
      float: left;
      margin-top: 24px;
      margin-bottom: 24px;

      .setImage {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        display: flex;
        margin-top: 20px;
        width: 15%;
        display: flex;
        align-items: center;
      }

      .leftBox {
        width: calc(100% - 80px);
        text-align: center;

        .updateSectionimg {
          width: 100%;

          .imgBoxEmpty {
            .imageShow {
              width: 112px;
              height: 150px;
              overflow: hidden;
              float: left;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }

              &:hover {
                .importImg {
                  display: flex;
                }
              }

              .importImg {
                display: none;
                position: absolute;
                top: 0px;
                left: 0px;
                height: 100%;
                margin: 0;
                width: 100%;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background: linear-gradient(0deg,
                    $BG_DARK_BLACK,
                    $BG_DARK_BLACK );

                .fileImported {
                  margin-bottom: 0px;
                }

                .icon-upload {
                  color: $TEXT_WHITE;
                  font-size: $FONT_40;
                  margin-bottom: 4px;
                }
              }
            }

            .uploadedImg {
              width: 100%;
              height: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: $BG_DARK_BLACK;

              .imageShow {
                width: 92px;
                height: 120px;
                object-fit: cover;
                position: absolute;
                left: 114px;
                top: 100px;
              }
            }
          }
        }

        .importImg {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;

          .icon-upload-1 {
            color: $TEXT_WHITE;
            margin-right: 16px;
          }

          p {
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 16px;
            color: $TEXT_WHITE;
          }
        }
      }
    }

    .fieldPartsName {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;

      .setName {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartsphone {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;

      .setPhone {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 15%;
        margin-right: 8px;
      }

      .phone_input {
        position: relative;
        width: 100%;
        display: block;
        margin-bottom: 16px;
        float: left;
        margin-left: 4px;
        background: #ffffff;
        color: #033963 !important;
        font-size: 12px;
        height: 40px;
        border-radius: 0px;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-weight: 400;
        font-family: "Helvetica";

        input {
          width: 100%;
          background: #ffffff;
          color: #033963 !important;
          font-size: 12px;
          padding: 6px 16px 6px 12px;
          height: 40px;
          border-radius: 0px;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          font-weight: 400;
          font-family: "Helvetica";
        }

        input::placeholder {
          color: #9BA0A8;
        }
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartsemail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      float: left;
      width: 100%;

      .setEmail {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartswebsite {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .setwebsite {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartstexteditor {
      display: flex;
      justify-content: space-between;
      align-items: center;
      float: left;
      width: 100%;
      margin-top: 18px;

      .settexteditor {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
        margin-bottom: 127px;
      }

      .updateSectioneditor {
        width: calc(100% - 90px);

        .ql-toolbar.ql-snow {
          background-color: $BG_WHITE;

          .ql-formats {
            margin: 0;

            button.ql-image {
              // display: none !important;
            }

            button {
              margin-right: 16px;
              width: 31px;
            }
          }
        }

        .ql-editor {
          background-color: $BG_WHITE;
          height: 108px;

          img {
            max-height: 250px;
            width: auto;
          }

          iframe {
            height: 250px;
            width: 100%;
          }
        }
      }
    }

    .fieldPartsradio {
      display: flex;
      justify-content: space-between;
      align-items: center;
      float: left;
      width: 100%;

      .setRadio {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 24px;
        margin-bottom: -14px;
      }

      .radio_row {
        display: flex;
        width: 100%;

        .RadioButton:nth-child(1) {
          margin-right: 16px;
        }
      }
    }

    .fieldParttiming {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 16px;

      .settiming {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .serviceTiming {
        width: calc(100% - 80px);
        background-color: $BG_WHITE;
        height: 250px;
        position: relative;
        margin-top: 16px;

        .weekendTiming {
          display: flex;
          margin-top: 32px;
          margin-left: 18px;

          .week {
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            display: block;
            color: $TEXT_DARK_BLUE_WEEK;
            width: 50%;
            padding-left: 8px;

            div {
              padding-bottom: 10px;
            }
          }

          .week1 {
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            display: block;
            color: $TEXT_DARK_BLUE_WEEK;
            padding-left: 8px;

            div {
              padding-bottom: 10px;
            }

          }
        }

        .weekendTiming1 {
          display: flex;
          margin-top: 10px;
          margin-left: 18px;
          margin-right: 0px !important;

          .week1 {
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            color: $TEXT_DARK_BLUE_WEEK;
            width: 50%;
            padding-left: 8px
          }

          .week2 {
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            color: $TEXT_DARK_BLUE_WEEK;
            padding-left: 8px
          }
        }

        .btnEdit {
          position: absolute;
          top: 5px;
          right: 8px;
          cursor: pointer;
        }
      }
    }

    .fieldPartsregion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      width: 100%;

      .setregion {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .BooktabDropdown {
        width: calc(100% - 80px);
        margin-bottom: 16px;

        .customDropdown {
          .dropdown {
            width: 100%;
            text-align: left;

            .dropdown-toggle {
              position: inherit;
              left: 0;
              background-color: $BG_WHITE;
              width: 100%;
              font-size: $FONT_12;
              height: 40px;
              color: $TEXT_PLACEHOLDER;
              text-align: left;
              border: none;
              position: relative;
              border-radius: 0;
              box-shadow: none;
              outline: none;

              &::after {
                content: "\e907";
                font-family: "birlinn" !important;
                border: none;
                font-size: $FONT_20;
                right: 8px;
                top: 6px;
                position: absolute;
              }
            }
          }

          .selected {
            &.dropdown {
              .btn-primary {
                color: $TEXT_DARK_BLUE !important;
              }
            }
          }

          .dropdown-menu {
            width: 100%;
            border-radius: 0;
            max-height: 400px;
            overflow-y: scroll;

            .dropdown-item {
              &:active {
                background-color: transparent !important;
                color: $BG_BLACK;
              }
            }
          }
        }
      }
    }

    .fieldPartsaddress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .setaddress {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 8px;
      }

      .textField {
        width: calc(100% - 80px);

        .form-control {
          padding: 10px !important;
        }
      }
    }

    .fieldPartslocationTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .setlocationtitle {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: $TEXT_WHITE;
        width: 14%;
        margin-right: 19px;
      }

      .locationRightPart {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
}

.servicesOffcanvas {
  width: 720px !important;
  background-color: $BODY_BG !important;

  .offcanvas-header {
    padding: 36px;
    padding-bottom: 0px !important;

    .alignCenters {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }
    }

    .updatebtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 92%;
      float: left;

      .donetitle {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_28;
        line-height: 36px;
        color: $BG_WHITE;
      }

      .customDonebtn {
        font-family: $HELVETICA !important;
        font-weight: 700 !important;
        font-size: $FONT_16;
        line-height: 18px;
        width: 81px;
        height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.5px;
      }
    }
  }

  .offcanvas-body {
    padding: 36px;

    .availableTitle {
      font-family: $HELVETICA;
      font-weight: 400;
      font-size: $FONT_22;
      line-height: 22px;
      color: $TEXT_WHITE;
    }

    table {
      width: 100%;
      position: relative;

      .serviceTable {
        th {
          background: $BTN_DISABLED;
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_14;
          line-height: 17px;
          color: $TEXT_BLUE;
          // padding: 10px;
        }

        .serviceDay {
          padding: 10px;
        }

        .serviceStart {
          position: absolute;
          padding: 10px;
          left: 20%;
        }

        .serviceEnd {
          position: absolute;
          padding: 10px;
          left: 46%;
        }

        .serviceHour {
          position: absolute;
          padding: 10px;
          left: 72%;
        }

        .serviceAction {
          position: absolute;
          padding: 10px;
          left: 87%;
        }
      }
    }

    .subTiming {
      margin-top: 8px;
      width: 100%;
      height: 435px;
      background: $BG_LIGHT;

      td {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: $TEXT_WHITE;
        padding: 12px;

        .timeDropdown {
          .dropdown {
            width: 122.4px;
            background: $BG_WHITE;
            border: none;

            .btn-primary {
              background-color: $BG_WHITE !important;
              font-family: $HELVETICA;
              font-weight: 400;
              font-size: $FONT_18;
              line-height: 21px;
              color: $TEXT_BLACK_DROP;
              border: 0px;
              border-radius: 0px;
              box-shadow: none;
              width: 100%;
              max-width: 122.4px;
              padding-right: 25px;
              margin-right: 17px;

              &::after {
                border-width: 0;
                content: "\e907";
                font-family: "Birlinn";
                position: absolute;
              }
            }

            .dropdown-menu {
              max-height: 500%;
              overflow-y: scroll;

              .dropdown-item {
                &:active {
                  background-color: transparent !important;
                  color: $BG_BLACK;
                }
              }
            }
          }
        }

        .timeDropdown1 {
          padding-right: 36px;

          .dropdown {
            width: 100%;

            .btn-primary {
              background-color: $BG_WHITE !important;
              font-family: $HELVETICA;
              font-weight: 400;
              font-size: $FONT_18;
              line-height: 21px;
              color: $TEXT_BLACK_DROP;
              border: 0px;
              border-radius: 0px;
              box-shadow: none;
              width: 100%;
              max-width: 122.4px;
              padding-right: 25px;

              &::after {
                border-width: 0;
                content: "\e907";
                font-family: "Birlinn";
                position: absolute;
              }
            }

            .dropdown-menu {
              max-height: 500%;
              overflow-y: scroll;

              .dropdown-item {
                &:active {
                  background-color: transparent !important;
                  color: $BG_BLACK;
                }
              }
            }
          }
        }

        .checkboxMain {
          padding-right: 22px;
        }
      }
    }
  }
}

.bookImportModal {
  .modal-dialog {
    width: 460px;

    .modal-content {
      padding: 16px 16px;
      position: absolute;
      top: 0;

      .updateSection {
        width: 100%;
        height: 150px;
        overflow: hidden;
        text-align: center;
        margin-bottom: 12px;
        position: relative;
        background-color: $BODY_BG;
        color: $TEXT_WHITE;

        input {
          display: none;
        }

        .importFileIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .icon {
            color: $BG_WHITE;
            margin-right: 8px;
            font-size: $FONT_18;
          }

          .csfileUploader {
            color: $TEXT_WHITE;
            font-size: $FONT_12;
            line-height: 14px;
            font-family: $HELVETICA;

            display: flex;
            align-items: center;
            justify-content: center;

            .fileName {
              max-width: 65%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0;
            }

            .fileSize {
              padding-left: 8px;
            }
          }
        }

        &:hover {
          .importImg {
            display: flex;
          }
        }

        .importImg {
          display: none;
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 100%;
          align-items: center;
          justify-content: center;

          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .btnInline {
    display: flex;
    justify-content: center;

    .custombtn {
      margin-right: 10px;
      box-shadow: none;
      width: 69px;
      height: 30px;
      display: flex;
      justify-content: center;
      padding: 6px 12px;
      font-family: $HELVETICA;
      font-weight: 400 !important;
      font-size: $FONT_12 !important;
      line-height: 16px;
      color: $TEXT_WHITE;
      letter-spacing: 0.4px;
      background-color: $BG_ORANGE;

      &:disabled {
        opacity: 0.5 !important;
        cursor: no-drop;
        background: $BG_DARK_ORANGE !important;
        pointer-events: all;
        color: $TEXT_WHITE !important;
        border: 1px solid transparent !important;
      }
    }

    .custombtn:nth-child(2) {
      width: 64px;
      height: 30px;
      color: $TEXT_DARK_BLUE;
      background-color: $BTN_MODEL_DISABLED;
    }
  }
}


@media only screen and (max-width: 1600px) {
  .shopPage {
    ul {
      .shopIndex {
        width: calc(33% - 15px) !important;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .shopPage {
    ul {
      .shopIndex {
        width: calc(33% - 15px) !important;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .shopMain {
    .setShop {

      // padding-right: 0px !important;
      .eventBtn {
        margin-top: 67px;
        margin-right: -188px;
      }
    }

    .setSearch {
      .tabButtonSearch {
        .searchBoxDiv {
          max-width: 500px !important;
        }
      }
    }
  }

  .leftHeader {
    .nav {
      margin-right: 66px;
    }
  }

  .shopPage {
    ul {
      .shopIndex {
        width: calc(33% - 15px) !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .shopMain {
    .setShop {

      // padding-right: 0px !important;
      .eventBtn {
        margin-top: 67px;
        margin-right: 12px;
      }
    }

    .setSearch {
      .tabButtonSearch {
        .searchBoxDiv {
          // max-width: 500px !important;
          max-width: 350px !important;
        }
      }
    }
  }

  .shopPage {
    ul {
      .shopIndex {
        // width: calc(33% - 15px) !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .shopMain {
    .setShop {
      padding-right: 0px !important;
    }

    .setSearch {
      .tabButtonSearch {
        .searchBoxDiv {
          max-width: 350px !important;
        }
      }
    }
  }

  .headingHeight {
    padding: 0 34px !important;

    .setShop {
      .title {
        font-size: 42px !important;
      }

      .eventBtn {
        margin-top: 67px;
        margin-right: 14px;
      }
    }

    .tabDropdown {
      .dropdown {
        .dropdown-menu {
          .titleApply {
            margin: 5px 0px 10px 83px !important;
          }
        }
      }
    }
  }

  .leftHeader {
    .nav {
      margin-right: 51px;
    }
  }

  .shopPage {
    padding: 0 6px 0 24px !important;

    ul {
      .shopIndex {
        width: calc(50% - 16px) !important;
      }
    }
  }
}

@media only screen and (max-width: 810px) {
  .shopMain {
    padding: 0 0 0 115px;
  }

  .headingHeight .setShop .title {
    font-size: 38px !important;
  }
}