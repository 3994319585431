//BG COLORS
$BG_WHITE: #ffffff;
$BG_WHITE_LIGHT: #F1EFEF;
$BODY_BG: #033963;
$OFFSET_BG: #29577a;
$BTN_DISABLED: #cbced3;
$BTN_MODEL_DISABLED: #c3c6cb;
$BG_RED: #dd0000;
$BG_RED_LIGHT: #EA5168;
$BG_ORANGE: #f7a637;
$BG_DARK_ORANGE: #ee7212;
$BG_GREEN: #6dd686;
$BG_DARK_GREEN_MAP: #1D6A50;
$BG_BLUEDARK: #6955fe;
$BG_BORDER: #666666;
$BG_PROFILE: #bed3f4;
$BG_YELLLOW: #ffc84a;
$BG_YELLLOW_LIGHT: #FDC84E;
$BG_BLACK: #000000;
$BG_NAVBAR: #f4f6f9;
$BG_SKY: #bfe3ff;
$BG_DARK_GREEN: #e33a3a;
$BG_GRAY: #e7e8eb;
$BG_GRAY_DARK: #7e7a7a;
$BG_SCROLLBAR: #006f9b;
$BG_DARK_SKY_BLUE: #1174BB;
$BG_LIGHTBLUE: #bfe3ff;
$BG_DARK_BLUE: #374151;
$BTN_MODEL_DISABLED: #c3c6cb;
$BG_LIGHTYELLOW: rgb(237, 228, 209);
$BG_DISABLED: rgba(191, 227, 255, 0.6);
$BG_LIGHT: rgba(255, 255, 255, 0.4);
$BG_LIGHT_BLACK: rgba(0, 0, 0, 0.25);
$BG_DARK_BLACK: rgba(0, 0, 0, 0.5);
$BG_BLUE: rgb(0 0 0 / 25%);
$BG_LIGHT: rgba(255, 255, 255, 0.05);
$SCROLLBAR_BG: #e6e6e6;

// Text Colors
$TEXT_WHITE: #ffffff;
$TEXT_BLACK: #000000;
$TEXT_BLACK_DROP: #0e1014;
$TEXT_LIGHT_GRAY: #d7d9dc;
$TEXT_PLACEHOLDER: #9ba0a8;
$BTN_TEXT_DISABLED: #999999;
$TEXT_RED: #ca5e5e;
$TEXT_GREEN: #6dd686;
$TEXT_BLUE: #033963;
$TEXT_DARK_BLUE_DARK : #022845;
$TEXT_BLUE_DARK: #1F2F47;
$TEXT_SUCCESS: #4bb526;
$TEXT_DARK_BLUE_WEEK: #1e232c;
$TEXT_YELLLOW: #ffc84a;
$TEXT_ORANGE: #f7a637;
$TEXT_DARK_BLUE: #374151;
$TEXT_DARK_YELLOW: #ee7212;
$TEXT_DARK_SKY: #29577a;
$TEXT_DISABLE: #4f7492;
$TEXT_MODAL : #FAFAFA;

// Font Size
$FONT_08: 08px;
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_13: 13px;
$FONT_14: 14px;
$FONT_15: 15px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_21: 21px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_26: 26px;
$FONT_28: 28px;
$FONT_30: 30px;
$FONT_32: 32px;
$FONT_36: 36px;
$FONT_37: 37px;
$FONT_57: 57px;
$FONT_38: 38px;
$FONT_40: 40px;
$FONT_50: 50px;
$FONT_57: 57px;
$FONT_LOBSTER: "Lobster", cursive;
$HELVETICA: "Helvetica";
