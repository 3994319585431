@import "../../../assets/scss/variable.scss";

.mainarea {
    background: $BODY_BG;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
    // overflow-y: auto;
    overflow: hidden;

    .titlebar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2.892% 4.82% 0;
        position: relative;

        .back_arrow {
            font-size: $FONT_36;
            text-decoration: none;
            color: $BG_WHITE;
        }

        .title_text {
            font-family: 'Lobster';
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_37;
            line-height: 42px;
            color: $BG_WHITE;
            width: 100%;
            text-align: center;
        }
    }

    .main_contain {
        overflow-y: scroll;
        padding-bottom: 40px;

        /* width */
      &::-webkit-scrollbar {
        width: 6px;
      }
  
      /* Track */
      &::-webkit-scrollbar-track {
        background: #66000000;
      }
  
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $SCROLLBAR_BG;
        border-radius: 10px;
      }
  
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #c0c0c0;
      }

        .regions_map {
            padding: 0 2.41% 0 4.82%;

            .regions_img {
                text-align: center;
                padding: 2.6% 8.315%;

                img {
                    width: 100%;
                }
            }
        }

        .buttons {
            text-align: end;
            padding: 4.88% 2.41% 0 4.82%;

            a {
                background: $BG_ORANGE;
                box-shadow: 4px 4px 0px $BG_DARK_ORANGE;
                text-decoration: none;
                color: $TEXT_WHITE;
            }

            a:first-child {
                padding: 8px 16px;
                font-size: $FONT_16;
            }

            a:nth-child(2) {
                padding: 8px 24px;
                margin: 0 10px;
                font-family: $HELVETICA;
                font-style: normal;
                font-weight: 700;
                font-size: $FONT_16;
                letter-spacing: 0.5px;
                line-height: 18px;
                box-shadow: 0px 4px 0px $TEXT_DARK_YELLOW;
            }
        }

        .text_body {
            padding: 5.785% 2.41% 0 4.82%;
            color: $TEXT_WHITE;
            font-family: $HELVETICA;
            font-style: normal;
            font-weight: 400;
            font-size: $FONT_14;
            line-height: 16px;
            letter-spacing: 0.25px;

            .description {
                margin: 0;
                overflow: hidden;
                height: 177px;
                margin-bottom: 10px;
            }
            span.Read_or_hide_btn {
                color: #F7A637;
                font-size: $FONT_16;
                font-weight: 800;
                cursor: pointer;
            }
            p.description.show {
                height: auto !important;
            }
        }

        .media {
            padding: 5.785% 2.41% 21.93% 4.82%;

            p {
                color: $TEXT_WHITE;
                margin: 0;
                padding-bottom: 5.198%;
            }

            .video {
                overflow:hidden;
                padding-bottom:56.25%;
                padding-top:30px;
                height:0;
                position:relative;

                .popup {
                    width: 100%;
                    height: 13rem;
                    position: absolute;
                    top: 0;
                    padding-bottom: 30px;
                    z-index: 111111;
                }
            }

            .video iframe,
            .video object,
            .video embed {
                top:0;
                left:0;
                width:100%;
                height:13rem;
                position:absolute;
            }
        }

        .right_side_books_list {
            padding: 5.785% 2.41% 0% 4.82%;

            p.books_list_highlight_title {
                font-family: "Helvetica";
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                align-items: center;
                letter-spacing: 0.25px;
                color: #ffffff;
                margin: 0 0 15px 0;
            }

            .recent_books_cards {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.2);
                box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                padding: 10px;
                width: 399px;
                max-width: 100%;
                margin-bottom: 10px;
                cursor: pointer;
            
                .book_card {
                    display: flex;
                    position: relative;
                    
                    .left_side {
                        img {
                            width: 90px;
                            height: 120px;
                        }
                    }
                    .right_side {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
            
                        .contain {
                            margin-left: 10px;
            
                            p.date {
                                margin: 0;
                                font-family: $HELVETICA;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                color: $TEXT_BLUE;
                            }
                            h3.book_name {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-weight: 700;
                                font-size: $FONT_18;
                                line-height: 18px;
                                letter-spacing: 0.15px;
                                color: $TEXT_BLUE;   
                            }
                            h4.book_author {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_12;
                                line-height: 14px;
                                color: $TEXT_BLUE;
                            }
                            h4.novel_name {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 400;
                                font-size: $FONT_14;
                                line-height: 16px;
                                letter-spacing: 0.25px;
                                color: $TEXT_BLUE;
                            }
                            h3.region_name {
                                margin: 10px 0 0 0;
                                font-family: $HELVETICA;
                                font-style: normal;
                                font-weight: 700;
                                font-size: $FONT_12;
                                line-height: 14px;
                                letter-spacing: 0.08em;
                                text-transform: uppercase;
                                color: $TEXT_DARK_BLUE_DARK;
                            }
                        }
            
                        .bookmark_area {
            
                            span.icon-fav {
                                font-size: $FONT_24;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }
                            span.icon-heart-fill {
                                font-size: $FONT_24;
                                color: $TEXT_WHITE;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}