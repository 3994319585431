@import "../../assets/scss/variable.scss";

.regionsMain {
  padding: 0 0 0 180px;
  width: 100%;
  background-image: url("https://res.cloudinary.com/zudu/image/upload/v1654764712/Birlinn/Web/Project-Images/background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: hidden;

  .headingHeight {
    padding: 0 45px;

    h1 {
      font-family: $FONT_LOBSTER;
      font-weight: 400;
      font-size: $FONT_57;
      letter-spacing: -0.25px;
      color: $TEXT_WHITE;
      padding-top: 20px;
      padding-bottom: 24px;
      margin: 0;
    }
    .tabButtonSearch {
      margin-top: 0px;

      .searchBoxDiv {
        position: relative;
        width: 750px;

        .searchInput {
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_12;
          line-height: 14px;
          padding: 0px 0px 0px 40px;
          border-radius: 0;
        }
        .search-icon {
          position: absolute;
          left: 0;
          top: 0;
        }
        .icon-x {
          position: absolute;
          top: 0;
        }
      }
    }
    p {
      font-family: $HELVETICA;
      font-weight: 700;
      font-size: $FONT_16;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $TEXT_WHITE;
      margin: 0;
      padding-bottom: 24px;
    }
  }

  .mainContainer {
    position: relative;
    width: 100%;
    display: block;
    float: left;
    overflow-y: auto;
    padding: 0px 40px 0px 30px;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $SCROLLBAR_BG;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $SCROLLBAR_BG;
    }

    ul {
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-flow: wrap;
    }
    li {
      position: relative;
      padding: 12px;
      list-style: none;
      width: 19% !important;
      background-color: $BG_SKY;
      float: left;
      margin: 0px 0px 16px 1%;
      padding-top: 60px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        background-color: $BG_WHITE;

        .mainIcons {
          .main {
            background-color: $BG_GRAY;
          }
        }
      }

      img {
        max-width: 248px;
        height: auto;
        display: block;
        margin: 0 auto;
        max-height: 163px;
        width: 100%;
        object-fit: contain;
      }

      h5 {
        font-family: $FONT_LOBSTER;
        font-weight: 400;
        font-size: $FONT_24;
        line-height: 30px;
        text-align: center;
        color: $TEXT_BLUE;
        margin-bottom: 12px;
      }

      p {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.4px;
        color: $TEXT_BLUE;
        margin-bottom: 7px;
        word-break: break-word;
      }
      .more_btn {
        display: flex;
        justify-content: end;
        margin-bottom: 5px;

        span {
          font-family: "Helvetica";
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          color: #ffffff;
          background-color: #f7a637 !important;
          padding: 3px 6px;
          border-radius: 9px;
          padding-bottom: 4px;
        }
      }
      .customEditbtn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-family: $HELVETICA;
        font-weight: 400 !important;
        font-size: $FONT_13 !important;
        line-height: 16px !important;
        letter-spacing: -0.004em;
        width: 64px !important;
        height: 32px !important;
        padding: 0px 8px 0px 0px;
        padding: 7px !important;
        display: none;

        &:not(.disabled):focus {
          box-shadow: none !important;
          background: $BG_ORANGE;
          box-shadow: 4px 4px 0px $BG_DARK_ORANGE !important;
        }

        i {
          margin-right: 9px;
          font-size: $FONT_13 !important;
        }
      }
      &:hover {
        .customEditbtn {
          display: block;
        }
      }
      .mainIcons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .main {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $BG_WHITE;
          margin: 0 4px;
          padding: 6px 8px;

          i {
            margin-right: 10px;
            color: $TEXT_ORANGE;
            font-size: $FONT_20;
          }

          h6 {
            margin: 0;
            font-family: $HELVETICA;
            font-weight: 700;
            font-size: 10px;
            line-height: 11px;
            color: $BG_DARK_BLUE;
          }
        }
      }
    }
  }
}

.regionOffcanvas {
  background-color: $BODY_BG !important;
  width: 600px !important;

  .offcanvas-header {
    padding: 36px;
    padding-bottom: 0px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .alignCenters {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
        cursor: pointer;
      }
      a {
        text-decoration: none;
      }
    }
    .title {
      font-family: $FONT_LOBSTER;
      font-weight: 400;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -1.5px;
      color: $TEXT_WHITE;
      margin-left: 10px !important;
    }
  }
  .offcanvas-body {
    padding: 0px 36px 36px 36px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $BODY_BG;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $BODY_BG;
    }

    .regionimg {
      display: block;
      margin: 0 auto;
      margin-bottom: 25px;
      width: 100%;
      max-width: 528px;
      height: 100%;
      object-fit: contain;
      max-height: 365px;
    }
    .offcanvasEditbtn {
      max-width: 92px;
      min-height: 42px;
      padding: 0;
    }
    .icon-edit-2 {
      color: $TEXT_WHITE;
    }
    .regiontext {
      font-family: $HELVETICA;
      font-weight: 400;
      font-size: $FONT_14;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $TEXT_WHITE;
      margin-top: 24px;
      margin-bottom: 24px;
      // margin: 0 36px;
      img {
        max-height: 300px;
      }
      iframe {
        width: 100%;
        height: 250px;
      }
    }
    .Icons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: -28px;
    }
    .off {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $BG_WHITE;
      margin: 24px 0px 0px 11px;
      padding: 0px 11px 0px 11px;
      color: $TEXT_DARK_BLUE;
    }
    i {
      margin-right: 8px;
      color: $OFFSET_BG;
      font-size: $FONT_15;
    }
    h6 {
      padding-top: 8px;
      font-family: $HELVETICA;
      font-weight: 700;
      font-size: $FONT_12;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: $OFFSET_BG;
    }
    .media {
      margin-top: 24px;
      p {
        font-family: $HELVETICA;
        font-weight: 700;
        font-size: $FONT_16;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: $TEXT_WHITE;
      }
      iframe {
        width: 100%;
        display: block;
      }
    }
  }
}

.chnageOffcanvas {
  width: 600px !important;
  background: $BODY_BG !important;
  .offcanvas-header {
    padding: 36px;
    padding-bottom: 0px !important;
    .alignCenters {
      i {
        font-size: $FONT_38;
        color: $TEXT_WHITE;
        font-weight: 400;
        cursor: pointer;
      }
      a {
        text-decoration: none;
      }
    }
    .updatebtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      float: left;

      .updatetitle {
        font-family: $FONT_LOBSTER;
        font-weight: 400;
        font-size: $FONT_36;
        line-height: 44px;
        letter-spacing: -1.5px;
        color: $TEXT_WHITE;
      }
      .customUpdatebtn {
        font-family: $HELVETICA !important;
        font-weight: 700 !important;
        font-size: $FONT_16;
        line-height: 18px;
        letter-spacing: 0.5px;
        max-width: 105px;
        min-height: 40px;

        &:not(.disabled):focus {
          box-shadow: none !important;
          background: $BG_ORANGE;
          box-shadow: 4px 4px 0px $BG_DARK_ORANGE !important;
        }
      }
    }
  }
  .offcanvas-body {
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $BODY_BG;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $BODY_BG;
    }

    .whiteBox {
      padding: 0px 20px;
      position: relative;

       form .updateSection .dropzoneCustom .txtDropzone {
        width: fit-content;
      }
      .uploadedImg {
        width: 216px;
        height: 216px;
        display: flex;
        justify-content: center;
        position: relative;

        &:hover {
          .hoverDiv {
            display: flex;
          }
        }

        .imgRegion {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .hoverDiv {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, $BG_DARK_BLACK, $BG_DARK_BLACK);
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .icon {
            color: $TEXT_WHITE;
            font-size: 54px;
          }
          .uploadText {
            font-size: $FONT_16;
            line-height: 19px;
            font-family: $HELVETICA;
            color: $TEXT_WHITE;
            margin: 0;
            margin-top: 10px;
          }
        }
      }

      .updateAudio {
        display: flex;
        justify-content: space-between;
        width: 100%;
        float: left;
        margin-top: 24px;

        .setAudio {
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_12;
          line-height: 14px;
          color: $TEXT_WHITE;
          margin-bottom: 115px;
          display: flex;
        }
        .rightBox {
          display: flex;
          background: $BG_LIGHT;
          width: calc(100% - 80px);
          height: 51px;
          text-align: center;
          justify-content: center;
          .uploadedAudio {
            p {
              margin-left: 8px;
            }
          }
          audio {
            margin-top: 8px;
            height: 35px;
          }
          .importAudio {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 14px;
            color: $TEXT_WHITE;
            text-align: center;

            .icon-log-in {
              color: $TEXT_WHITE;
              font-size: 18px;
            }
          }
        }
      }
      .updateVideo {
        display: flex;
        justify-content: space-between;
        width: 100%;
        float: left;
        margin-top: -77px;

        .setVideo {
          font-family: $HELVETICA;
          font-weight: 400;
          font-size: $FONT_12;
          line-height: 14px;
          color: $TEXT_WHITE;
          margin-bottom: 115px;
          display: flex;
        }
        .rightBox2 {
          display: flex;
          background: $BG_DISABLED;
          width: calc(100% - 80px);
          height: 107px;
          text-align: center;
          justify-content: center;
          .uploadedVideo {
            p {
              margin-left: 8px;
              margin-bottom: 0px;
            }
          }
          video {
            height: 97px;
            margin-top: 5px;
          }
          .importVideo {
            display: flex;
            justify-content: space-between;
            margin-top: 47px;
            font-family: $HELVETICA;
            font-weight: 400;
            font-size: $FONT_12;
            line-height: 14px;
            color: $TEXT_WHITE;
            text-align: center;
            .icon-log-in {
              color: $TEXT_WHITE;
              font-size: 18px;
            }
            h4 {
              font-family: $HELVETICA;
              font-weight: 400;
              font-size: $FONT_12;
              line-height: 14px;
              color: $TEXT_WHITE;
              text-align: center;
            }
          }
        }
      }
    }
    .mainEditor {
      display: flex;
      justify-content: space-between;
      width: 100%;
      float: left;
      margin-top: -20px;
      .setEditor {
        font-family: $HELVETICA;
        font-weight: 400;
        font-size: $FONT_12;
        line-height: 14px;
        color: $TEXT_WHITE;
        margin-bottom: 95px;
        display: flex;
      }
      .updateSectioneditor {
        width: calc(100% - 80px);
        .ql-toolbar.ql-snow {
          background-color: $BG_WHITE;
          .ql-formats {
            margin: 0;
            button {
              margin-right: 16px;
              width: 31px;
            }
          }
        }
        .ql-editor {
          background-color: $BG_WHITE;
          height: 292px;
          img {
            max-height: 250px;
            width: auto;
          }
          iframe {
            height: 250px;
            width: 100%;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1600px) {
  .mainContainer {
    ul {
      .li {
        width: 48.5%;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .mainContainer {
    ul {
      .li {
        width: 24% !important;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .regionsMain {
    .tabButtonSearch {
      .searchBoxDiv {
        max-width: 500px !important;
      }
    }
  }
  .mainContainer {
    ul {
      .li {
        width: 24%;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .regionsMain {
    .tabButtonSearch {
      .searchBoxDiv {
        max-width: 500px !important;
      }
    }
  }
  .mainContainer {
    ul {
      .li {
        width: 24% !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .regionsMain {
    .headingHeight {
      h1 {
        font-size: 42px !important;
      }
      .tabButtonSearch {
        .searchBoxDiv {
          width: 350px !important;
        }
      }
    }
    .mainContainer {
      padding: 0px 15px !important;
      ul {
        li {
          width: 48.5% !important;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .regionsMain {
    .tabButtonSearch {
      .searchBoxDiv {
        max-width: 500px !important;
      }
    }
    .mainContainer {
      ul {
        li {
          width: 48.5% !important;
        }
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .regionsMain {
    .tabButtonSearch {
      .searchBoxDiv {
        max-width: 500px !important;
      }
    }
    .mainContainer {
      ul {
        li {
          width: 32% !important;
        }
      }
    }
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {
  .regionsMain {
    .mainContainer {
      ul {
        li {
          width: 24% !important;
        }
      }
    }
  }
}
@media (min-width: 1441px) and (max-width: 1600px) {
  .regionsMain {
    .mainContainer {
      ul {
        li {
          width: 24% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 810px) {
  .regionsMain {
    padding: 0 0 0 115px;
  }
}
